import React from "react";

export default function Stepper({ steps, selectedStep }) {
  return (
    <div class="relative border border-[#E5E5E5] rounded-full overflow-hidden">
      <div className="flex justify-center items-center sm:gap-0 gap-3 overflow-auto relative">
        {steps.map((stepDetail, index) => {
          return (
            <div
              class={` flex-1 text-center border-l border-[#E5E5E5] first:border-l-0 ${
                selectedStep === index ? " bg-[#9A45A3]" : ""
              } ${index <= selectedStep && "bg-[#9A45A3] border-l-[#9A45A3]"}`}
            >
              <span
                class={`block pt-[6px] pb-[7px] text-xs leading-[15px] font-semibold whitespace-nowrap ${
                  index < selectedStep && "text-white opacity-50"
                }  ${
                  selectedStep !== index
                    ? "text-[#121212] opacity-50"
                    : "text-white opacity-100"
                }`}
              >
                {stepDetail}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
