import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import {
  ArchiveIcon,
  ArrowBackIcon,
  ArrowRight,
  CompletedGreen,
  EditIcon,
  FilterIcon,
  HorizontalDots,
  NoteIcon,
  PhoneMissedGrayIcon,
  PhoneMissedSecondaryIcon,
  SearchBlue,
  TranscriptionIcon,
  TrashIcon,
  UserUploadPrimary,
  WarningInfoIcon,
} from "assets";
import TabsComponent from "component/Tabs";
import JobList from "./jobList";
import CustomizedTable, {
  StyledTableCell,
  StyledTableRow,
} from "component/Table";
import {
  cancelAllCalls,
  cancelSingleCall,
  deleteCandidate,
  dialAllCalls,
  executeAICall,
  getJobById,
  getJobCandidates,
  getJobs,
  updateCandidate,
  updateJob,
  updateJobStatus,
} from "services/Job";
import {
  callStatusDetail,
  categoryChipColor,
  debounce,
  formatToK,
  getListTableColumns,
  renderCategoryValue,
} from "utils/common";
import UploadCandidates from "dialogs/UploadCandidates";
import MenuPopper from "component/MenuPopper";
import TranscriptionDetail from "dialogs/TranscriptionDetail";
import DeleteAccount from "dialogs/deleteAccount";
import SummaryDetail from "./summaryDetail";
import CandidateDetailSkelaton from "./candidateDetailSkeleton";
import CandidateEmptyScreen from "./candidateEmptyScreen";
import NewJobDialog from "dialogs/NewJob";
import InfoDialog from "dialogs/InfoDialog";
import CandidateFilters from "component/CandidateFilter";
import CustomTooltip from "component/Tooltip";
import CallInfo from "dialogs/callInfoDialog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "component/Loader";
import JobListMenuPopper from "component/JobListMenu";
import AIChat from "pages/AIChat";
import SubscriptionPlanDialog from "dialogs/subscriptionDialog";
import AddCallsDialog from "dialogs/addCallsDialog";
import { Paths } from "routes";

const initialInfoDialog = {
  open: false,
  infoDetail: null,
  icon: "",
  id: "",
};

export default function JobDetail({ auth }) {
  const { jobId } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const currentRouteSubPage = currentPath?.split("/")?.[2]; // ai-chat

  const navigate = useNavigate();
  const [mainLoader, setMainLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedJobDetail, setSelectedJobDetail] = useState(null);
  const [selectedCandidates] = useState([]);
  const [candidateList, setCandidateList] = useState([]);
  const categoryRef = useRef("");
  const [totalCandidate, setTotalCandidate] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef(currentPage);
  const [tabValue, setTabValue] = useState(
    currentRouteSubPage === "ai-chat" ? 1 : 0
  );
  const [uploadCandidate, setUploadCandidate] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef(searchValue);
  const [menuPopperRef, setMenuPopperRef] = useState(null);
  const [transcriptionDetail, setTranscriptionDetail] = useState(false);
  const [deleteCandidatePopup, setDeleteCandidatePopup] = useState(false);
  const [removeFromStrongCandidate, setRemoveFromStrongCandidate] =
    useState(false);
  const [confirmDeleteJobPopup, setConfirmDeleteJobPopup] = useState(false);
  const [isCandidateCallSummary, setIsCandidateCallSummary] = useState(false);
  const [isFromSingleCandidate, setIsFromSingleCandidate] = useState(false);
  const [isCallInfoDialog, setIsCallInfoDialog] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [infoDialogDetail, setInfoDialogDetail] = useState(initialInfoDialog);
  const [isAddCreditsPopup, setIsAddCreditsPopup] = useState(false);
  const [moreVertMenuAnchorEl, setMoreVertMenuAnchorEl] = useState(null);
  const [moreVertAction, setMoreVertAction] = useState(""); // edit_job_details || edit_call || archive_job
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [appliedFilters, setAppliedFilter] = useState({
    category: "all",
  });
  const [jobListDropdownMenu, setJobListDropdownMenu] = useState(false);
  const [jobList, setJobList] = useState(null);
  const [subscriptionPopup, setSubscriptionPopup] = useState(false);
  const [addCreditsPopup, setAddCreditsPopup] = useState(false);
  const [btnLoading, setBtnLoading] = useState("");

  const fetchCandidates = async (
    offset = 10 * (currentPage - 1),
    value = searchValue,
    limit = 10,
    category = categoryRef.current,
    silent = false
  ) => {
    try {
      // If JobDetail is not available or call from settimeout, fetch both JobDetail and candidates in parallel
      if (!selectedJobDetail || silent) {
        if (!silent) setMainLoader(true);
        const [JobDetail, result] = await Promise.all([
          getJobById(jobId),
          getJobCandidates(jobId, value, limit, offset, category),
        ]);

        if (!JobDetail) {
          navigate(Paths.JobOverview);
          return;
        }

        setSelectedJobDetail(JobDetail);
        setCandidateList(result?.data?.result?.candidates);
        setTotalCandidate(Number(result?.data?.result?.totalCount || 0));
      } else {
        if (!silent) setLoading(true);
        const result = await getJobCandidates(
          selectedJobDetail?.id,
          value,
          limit,
          offset,
          category
        );
        setCandidateList(result?.data?.result?.candidates);
        setTotalCandidate(Number(result?.data?.result?.totalCount || 0));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setMainLoader(false);
      setLoading(false);
    }
  };

  const fetchJobList = async () => {
    if (!jobList) {
      const result = await getJobs("", "active");
      setJobList(result?.data?.result);
    }
  };

  const updateJobDetail = async () => {
    const jobDetail = await getJobById(jobId);
    setSelectedJobDetail(jobDetail);
  };

  const triggerAICall = async (candidateId) => {
    if (
      _.get(auth, "userData.Subscription[0].status", "") !== "active" &&
      _.get(auth, "userData.Subscription[0].status", "") !== "trialing"
    ) {
      setSubscriptionPopup(true);
    } else {
      setLoading(true);
      try {
        await executeAICall(selectedJobDetail?.id, candidateId);
        const hidePopup = localStorage.getItem("hideCallInfoPopup");
        if (!hidePopup || hidePopup !== "true") {
          setIsCallInfoDialog(true);
        }
      } catch (error) {
        setIsAddCreditsPopup(true);
        console.error("Error triggering AI call:", error);
      }
      await fetchCandidates();
    }
  };

  useEffect(() => {
    fetchCandidates();

    // this api call in every 5sec even we're in ai chat tab need to restrict
    const intervalId = setInterval(() => {
      fetchCandidates(
        10 * (currentPageRef.current - 1),
        searchRef.current,
        undefined,
        categoryRef.current,
        true
      );
    }, 5000);

    return () => clearInterval(intervalId);
  }, [jobId]);

  useEffect(() => {
    if (selectedCandidate && candidateList?.length > 0) {
      const updatedSelectedCandidate = candidateList?.find(
        (candidate) => candidate?.id === selectedCandidate?.id
      );
      updatedSelectedCandidate &&
        setSelectedCandidate(updatedSelectedCandidate);
    }
  }, [candidateList]);

  const handleBackClick = () => {
    if (isCandidateCallSummary) {
      setIsCandidateCallSummary(false);
    } else {
      navigate(Paths.JobOverview);
    }
  };

  // const handleSelect = (event) => {
  //   const { value } = event.target;

  //   setCandidateType(value);
  //   categoryRef.current = value;
  //   fetchCandidates(undefined, undefined, undefined, value);
  // };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    currentPageRef.current = newPage;
    fetchCandidates(10 * (newPage - 1));
  };

  const handleCandiateClick = (row) => {
    // const candidateSummary = row?.summary
    //   ? JSON.parse(row?.summary || {})
    //   : null;

    setIsFromSingleCandidate(true);
    setIsCandidateCallSummary(true);
    setSelectedCandidate(row);
  };

  const debouncedSearch = debounce(fetchCandidates, 1000);

  const handleSearchCandidate = (value) => {
    setSearchValue(value);
    searchRef.current = value;
    debouncedSearch(0, value);
  };

  const handleMenuClick = (id) => {
    setMenuPopperRef(null);
    if (id === "view_transcript") {
      setTranscriptionDetail(true);
    } else if (id === "remove") {
      setDeleteCandidatePopup(true);
    } else if (id === "view_notes") {
      setIsFromSingleCandidate(false);
      setIsCandidateCallSummary(true);
      // const candidateSummary = selectedCandidate?.summary
      //   ? JSON.parse(selectedCandidate?.summary || {})
      //   : null;
      // candidateSummary &&
      //   candidateSummary?.summary &&
      //   setCandidateCallSummary(candidateSummary?.summary);
    } else if (id === "remove_from_strong_candidate") {
      setRemoveFromStrongCandidate(true);
      setDeleteCandidatePopup(true);
    }
  };

  const handleMoreVertMenuClick = async (id) => {
    setMoreVertMenuAnchorEl(null);
    if (id === "edit_job_details" || id === "edit_call") {
      setMoreVertAction(id);
    } else if (id === "remove_job") {
      setConfirmDeleteJobPopup(true);
    } else {
      const response = await updateJobStatus(selectedJobDetail?.id, {
        status: "archived",
      });
      if (response.data) {
        setInfoDialogDetail({
          icon: CompletedGreen,
          infoDetail: {
            heading: "Changes Saved!",
            innerContent: "",
          },
          open: true,
        });
        await updateJobDetail();
      }
    }
  };

  const handleDeleteCandidate = async () => {
    if (removeFromStrongCandidate) {
      setRemoveFromStrongCandidate(false);
      await updateCandidate(selectedJobDetail?.id, selectedCandidate?.id, {
        category: "interested",
      });
    } else {
      await deleteCandidate(selectedJobDetail?.id, selectedCandidate?.id);
      setIsCandidateCallSummary(false);
      setSelectedCandidate(null);
    }
    fetchCandidates();
  };

  const handleUpdateJob = async (jobData) => {
    const result = await updateJob(selectedJobDetail?.id, jobData);
    setMoreVertAction("");
    if (result?.data?.result) {
      setInfoDialogDetail({
        icon: CompletedGreen,
        infoDetail: {
          heading: `Changes Saved!.`,
          innerContent: "",
        },
        open: true,
      });
      await updateJobDetail();
    }
  };

  const handleDeleteJob = async () => {
    const response = await updateJobStatus(selectedJobDetail?.id, {
      status: "deleted",
    });
    if (response.data) {
      navigate(Paths.JobOverview);
    }
  };

  const handleSaveFilter = (appliedFilters) => {
    setAppliedFilter(appliedFilters);
    setFilterAnchorEl(null);
    setCurrentPage(1);
    categoryRef.current = appliedFilters?.category;
    fetchCandidates(0, undefined, undefined, appliedFilters?.category);
  };

  const renderExperienceUI = (experience = 0) => {
    const numSquares = Math.ceil(Number(experience || 0));

    return (
      <div className="flex gap-[6px] items-center">
        <div className="flex gap-1">
          {Array.from({ length: 5 }).map((_, index) => (
            <div
              key={index}
              className={`w-[12px] h-[12px] ${
                index < numSquares ? "bg-[#2764D2]" : "bg-[#2764d233]"
              } rounded-[2px]`}
            ></div>
          ))}
        </div>
        <p className="text-[#353535] font-normal text-[12px] leading-[18px]">
          {numSquares} Years
        </p>
      </div>
    );
  };

  const tableColumns = useMemo(() => {
    return getListTableColumns("job");
  }, []);

  const handleDialAllCall = async () => {
    try {
      setBtnLoading("dial_all_call");
      await dialAllCalls(jobId);
      setBtnLoading("");
      fetchCandidates();
    } catch (error) {
      setBtnLoading("");
      if (
        error?.response &&
        error?.response?.data?.code === "plan_limit_exceeded"
      ) {
        setInfoDialogDetail({
          open: true,
          icon: WarningInfoIcon,
          infoDetail: {
            heading: error?.response?.data?.title,
          },
          id: error?.response?.data?.code,
        });
      } else if (
        error?.response &&
        error?.response?.data?.code === "credit_limit_exceeded"
      ) {
        setIsAddCreditsPopup(true);
      }
    }
  };

  const handleStopAllCalls = async () => {
    try {
      await cancelAllCalls(jobId);
      fetchCandidates();
    } catch (err) {
      console.log("error", err);
    }
  };

  const handleCancelSingleCall = async (candidateId) => {
    try {
      await cancelSingleCall(jobId, candidateId);
      fetchCandidates();
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <Fragment>
      {subscriptionPopup && (
        <SubscriptionPlanDialog
          open={subscriptionPopup}
          setOpen={setSubscriptionPopup}
        />
      )}
      {addCreditsPopup && (
        <AddCallsDialog open={addCreditsPopup} setOpen={setAddCreditsPopup} />
      )}
      {jobListDropdownMenu && (
        <JobListMenuPopper
          anchorRef={jobListDropdownMenu}
          handleClose={() => {
            setJobListDropdownMenu(null);
          }}
          jobList={jobList}
          handleJobClick={(jobId) => {
            setSelectedJobDetail(null);
            setJobListDropdownMenu(null);
            navigate(`/job/${jobId}`);
          }}
        />
      )}
      {infoDialogDetail.open && (
        <InfoDialog
          open={true}
          setOpen={(open) => setInfoDialogDetail(initialInfoDialog)}
          infoDetail={infoDialogDetail.infoDetail}
          handleSubmit={() => {
            if (infoDialogDetail.id === "plan_limit_exceeded") {
              setSubscriptionPopup(true);
            }
          }}
          icon={infoDialogDetail.icon}
        />
      )}
      <UploadCandidates
        open={uploadCandidate}
        setOpen={setUploadCandidate}
        jobId={selectedJobDetail?.id}
        handleCallBack={() => fetchCandidates()}
      />
      {menuPopperRef && (
        <MenuPopper
          open={Boolean(menuPopperRef)}
          anchorRef={menuPopperRef}
          handleClose={() => {
            setMenuPopperRef(null);
            setSelectedCandidate(null);
          }}
          menuItems={[
            ...(!(selectedCandidate && !selectedCandidate?.recordingDownloaded)
              ? [
                  {
                    id: "view_transcript",
                    label: "View Transcript",
                    icon: TranscriptionIcon,
                    disabled:
                      selectedCandidate &&
                      !selectedCandidate?.recordingDownloaded,
                  },
                ]
              : []),
            {
              id: "view_notes",
              label: "View Notes",
              icon: NoteIcon,
            },
            // { id: "remove", label: "Remove", icon: TrashIcon },
            ...(selectedCandidate?.callStatus !== "in-progress" &&
            selectedCandidate?.callStatus !== "ringing" &&
            selectedCandidate?.callStatus !== "triggered" &&
            selectedCandidate?.callStatus !== "triggering"
              ? [
                  {
                    id: "remove",
                    label: "Remove",
                    icon: TrashIcon,
                  },
                ]
              : []),
            ...(selectedCandidate?.category === "strong_candidate"
              ? [
                  {
                    id: "remove_from_strong_candidate",
                    label: "Remove from strong candidate",
                    icon: TrashIcon,
                  },
                ]
              : []),
          ]}
          handleMenuClick={(id) => handleMenuClick(id)}
        />
      )}
      {isCallInfoDialog && (
        <CallInfo
          open={isCallInfoDialog}
          setOpen={setIsCallInfoDialog}
          handleSubmit={() => {}}
          infoDetail={{
            heading: "Calls in Queue",
            innerContent:
              "Your call requests have been made. Your candidates will start to receive calls in 1 minute. You can see status to track the progress of each call.",
          }}
        />
      )}
      {isAddCreditsPopup && (
        <InfoDialog
          open={isAddCreditsPopup}
          setOpen={setIsAddCreditsPopup}
          infoDetail={{
            heading:
              _.get(auth, "userData.UserData.credits", 0) === 0
                ? "Your calls credits are finished for today"
                : "You don't have enough credits to make calls to all candidates.",
            innerContent:
              _.get(auth, "userData.UserData.credits", 0) === 0
                ? "it will be available tomorrow."
                : "You can try calling candidates one by one.",
          }}
          handleSubmit={() => {
            setIsAddCreditsPopup(false);
            //setAddCreditsPopup(true);
          }}
          icon={undefined}
          buttonText="Close"
          buttonClass="max-w-[220px]"
        />
      )}

      <TranscriptionDetail
        open={transcriptionDetail}
        setOpen={setTranscriptionDetail}
        selectedCandidate={selectedCandidate}
      />
      {deleteCandidatePopup && (
        <DeleteAccount
          open={deleteCandidatePopup}
          setOpen={setDeleteCandidatePopup}
          handleSubmit={handleDeleteCandidate}
          icon={WarningInfoIcon}
          infoDetail={{
            heading: `Remove ${selectedCandidate?.firstName} from ${
              removeFromStrongCandidate ? "strong" : ""
            } candidates list?`,
          }}
        />
      )}
      {moreVertMenuAnchorEl && (
        <MenuPopper
          open={Boolean(moreVertMenuAnchorEl)}
          anchorRef={moreVertMenuAnchorEl}
          handleClose={() => {
            setMoreVertMenuAnchorEl(null);
          }}
          menuItems={[
            {
              id: "edit_job_details",
              label: "Edit Job Details",
              icon: EditIcon,
            },
            {
              id: "edit_call",
              label: "Edit Call",
              icon: EditIcon,
            },
            {
              id: "archive_job",
              label: "Archive Job",
              icon: ArchiveIcon,
              disabled: selectedJobDetail?.status === "archived",
            },
            {
              id: "remove_job",
              label: "Remove",
              icon: TrashIcon,
              disabled:
                selectedJobDetail?.callStatus === "in-progress" ||
                selectedJobDetail?.callStatus === "ringing" ||
                selectedJobDetail?.callStatus === "triggered" ||
                selectedJobDetail?.callStatus === "triggering" ||
                selectedJobDetail?.callStatus === "initiated",
            },
          ]}
          handleMenuClick={(id) => handleMoreVertMenuClick(id)}
        />
      )}
      {(moreVertAction === "edit_job_details" ||
        moreVertAction === "edit_call") && (
        <NewJobDialog
          open={true}
          setOpen={() => setMoreVertAction("")}
          handleCreateJob={handleUpdateJob}
          mode="edit"
          jobData={selectedJobDetail}
          editState={moreVertAction}
        />
      )}
      {confirmDeleteJobPopup && (
        <DeleteAccount
          open={confirmDeleteJobPopup}
          setOpen={setConfirmDeleteJobPopup}
          handleSubmit={handleDeleteJob}
          icon={WarningInfoIcon}
          infoDetail={{
            heading: `Are you sure you want to delete job?`,
          }}
        />
      )}
      {filterAnchorEl && (
        <CandidateFilters
          anchorEl={filterAnchorEl}
          handleClose={() => setFilterAnchorEl(null)}
          handleSaveFilter={(appliedFilters) =>
            handleSaveFilter(appliedFilters)
          }
          appliedFilters={appliedFilters}
          module="job"
        />
      )}
      <div className="py-4 sm:py-6 lg:py-8 px-4 sm:px-6 lg:px-10 h-[calc(100vh_-_75px)]">
        <div className="h-full bg-[#FDFEFF] border border-[#E5E5E5] rounded-xl overflow-hidden">
          <div className="w-full h-full flex flex-col bg-[#FDFEFF] rounded-xl">
            {mainLoader ? (
              <Loader />
            ) : (
              <div className="h-full w-full bg-white rounded-xl flex flex-col">
                <div
                  className={`px-8 pt-4 ${
                    isCandidateCallSummary
                      ? "pb-[92px]"
                      : tabValue === 1
                      ? "pb-0"
                      : "py-4"
                  } flex flex-col h-full ${
                    isCandidateCallSummary ? "overflow-hidden" : ""
                  }`}
                >
                  <div className="flex justify-between items-center mb-3">
                    <button
                      className="flex items-center gap-1 text-[#121212] text-sm font-semibold leading-[19px]"
                      onClick={() => handleBackClick()}
                    >
                      <img src={ArrowBackIcon} alt="back-arrow" />
                      Back
                    </button>
                    {!isCandidateCallSummary && (
                      <div className="flex gap-2">
                        <button
                          className="flex items-center gap-1 text-[#121212] text-sm font-semibold leading-[19px]"
                          onClick={(event) => {
                            setJobListDropdownMenu(event.currentTarget);
                            fetchJobList();
                          }}
                        >
                          {selectedJobDetail?.title}
                          <img src={ArrowRight} alt="back-arrow" />
                        </button>
                        <div
                          className="p-1 cursor-pointer"
                          onClick={(event) =>
                            setMoreVertMenuAnchorEl(event.currentTarget)
                          }
                        >
                          <img src={HorizontalDots} alt="cheveron" />
                        </div>
                      </div>
                    )}
                  </div>

                  {isCandidateCallSummary ? (
                    <SummaryDetail
                      selectedCandidate={selectedCandidate}
                      selectedJobDetail={selectedJobDetail}
                      isFromSingleCandidate={isFromSingleCandidate}
                      deleteCandidate={handleDeleteCandidate}
                      handleExecuteCall={() =>
                        triggerAICall(selectedCandidate?.id)
                      }
                    />
                  ) : (
                    <Fragment>
                      <JobList job={selectedJobDetail} isFromJobDetail={true} />
                      <TabsComponent
                        tabValue={tabValue}
                        setTabValue={(newValue) => {
                          setTabValue(newValue);
                          newValue === 1
                            ? navigate(`/job/${jobId}/ai-chat`)
                            : navigate(`/job/${jobId}`);
                        }}
                        tabItems={[
                          { label: "Call List" },
                          { label: "AI Chat" },
                        ]}
                        isFromJobDetail={true}
                      />
                      {tabValue === 0 ? (
                        <Fragment>
                          {/* Filters and Actions */}
                          <div className="w-full flex gap-3 items-center mt-5 mb-4 justify-between xl:flex-nowrap flex-wrap">
                            <div className="w-auto flex items-center gap-3 justify-start">
                              <h6 className="text-[20px] font-medium leading-6 text-[#353535] whitespace-nowrap">
                                Call List
                              </h6>
                              {/* <Select
                              value={candidateType}
                              onChange={handleSelect}
                              variant="outlined"
                              sx={{
                                "&.Mui-focused": {
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#e2e2e2",
                                  },
                                },
                                "&:hover": {
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#e2e2e2",
                                  },
                                },
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: "1px solid #e2e2e2",
                                  borderRadius: "6px",
                                },
                              }}
                              className="w-fit min-w-[148px] max-h-[36px] py-3 px-2 md:px-[14px] !text-[#121212] !text-[13px] !font-medium !leading-4 focus-visible:outline-none"
                              classes={{
                                select: "!p-0",
                              }}
                            >
                              <MenuItem
                                value="all"
                                className="!text-[#121212] !text-[13px] !font-normal !leading-4"
                              >
                                All Candidates
                              </MenuItem>
                              <MenuItem
                                value="strong"
                                className="!text-[#121212] !text-[13px] !font-normal !leading-4"
                              >
                                Strong Candidates
                              </MenuItem>
                            </Select> */}

                              {/* <div
                                className="border border-[#e5e5e5] rounded-md px-2 md:px-4 py-[5px] ml-auto text-[#121212] cursor-pointer"
                                onClick={() => fetchCandidates()}
                              >
                                <RefreshIcon color="primary" />
                              </div> */}
                            </div>
                            <div className="w-full flex flex-col xl:justify-end justify-start items-start md:flex-row md:items-center gap-3 flex-wrap">
                              <div
                                className="cursor-pointer border border-[#E5E5E5] rounded-md min-w-9 h-9 p-[10px]"
                                onClick={() => setUploadCandidate(true)}
                              >
                                <img
                                  src={UserUploadPrimary}
                                  alt="userUpload"
                                  width={16}
                                  height={16}
                                />
                              </div>
                              <div
                                className={`w-full md:max-w-[232px] flex items-center gap-1 border-[1.2px] border-[#e5e5e5] rounded-lg py-2 pl-[10px]`}
                              >
                                <img src={SearchBlue} alt="search-icon" />
                                <input
                                  type="text"
                                  placeholder="Search for Candidate(s)"
                                  value={searchValue}
                                  className="text-sm leading-5 font-normal text-[#121212] border-none focus-visible:outline-none"
                                  onChange={(e) =>
                                    handleSearchCandidate(e.target.value)
                                  }
                                />
                              </div>
                              <button
                                onClick={(event) =>
                                  setFilterAnchorEl(event.currentTarget)
                                }
                                className="md:w-fit w-full flex items-center px-2 md:px-4 py-2 rounded-md  border border-[#e2e2e2] text-[#121212] text-[13px] font-normal leading-4"
                              >
                                <img
                                  src={FilterIcon}
                                  alt="filter-icon"
                                  width={18}
                                  height={18}
                                />
                                Filters
                              </button>
                              {/* <button
                                className="border border-[#2764D2] rounded-md px-4 py-[10px] text-[#121212] text-[13px] font-medium leading-4 whitespace-nowrap"
                                disabled={selectedCandidates.length === 0}
                                onClick={() => {
                                  exportToCSV(
                                    candidateList.filter((c) =>
                                      selectedCandidates.includes(c.id)
                                    )
                                  );
                                  setSelectedCandidates([]);
                                }}
                              >
                                Export as CSV
                              </button> */}
                              <button
                                className="md:w-auto w-full bg-custom-primary text-white rounded-md px-4 py-[10px] h-[36px] text-[13px] font-medium leading-4 whitespace-nowrap disabled:opacity-75"
                                onClick={() => handleDialAllCall()}
                                disabled={btnLoading === "dial_all_call"}
                              >
                                Call Everyone
                              </button>
                              <button
                                className="md:w-auto w-full bg-[#F83A3A] text-white rounded-md px-4 py-[10px] h-[36px] text-[13px] font-medium leading-4 whitespace-nowrap disabled:opacity-75"
                                onClick={() => handleStopAllCalls()}
                              >
                                Stop All Calls
                              </button>
                            </div>
                          </div>
                          {/* Candidate Table Part */}
                          <div
                            className={`flex-grow ${
                              candidateList?.length === 0 && !loading
                                ? "overflow-auto scrollbar"
                                : "overflow-hidden"
                            }`}
                          >
                            {candidateList?.length === 0 && !loading ? (
                              <CandidateEmptyScreen
                                handleRefresh={() => fetchCandidates()}
                                columns={tableColumns}
                                module="job"
                              />
                            ) : (
                              <CustomizedTable
                                columns={tableColumns}
                                totalCount={totalCandidate}
                                handlePageChange={(e, page) =>
                                  handlePageChange(page)
                                }
                                currentPage={currentPage}
                                selectedCandidates={selectedCandidates}
                              >
                                {loading
                                  ? [0, 1, 2, 3, 4].map((_, index) => (
                                      <CandidateDetailSkelaton />
                                    ))
                                  : candidateList.map((row, index) => {
                                      const summary = row?.summary
                                        ? JSON.parse(row?.summary || {})
                                        : null;

                                      const statusDetail = callStatusDetail(
                                        row?.callStatus
                                      );
                                      const isCancelCallDisabled =
                                        row.callStatus === "in-progress" ||
                                        row.callStatus === "ringing" ||
                                        row.callStatus === "triggered" ||
                                        row.callStatus === "triggering";
                                      return (
                                        <StyledTableRow
                                          key={index}
                                          onClick={() =>
                                            handleCandiateClick(row)
                                          }
                                          className="cursor-pointer"
                                        >
                                          {/* {tabValue === 0 && <StyledTableCell
                                            component="td"
                                            scope="row"
                                            className="w-[10%] p-2 text-center"
                                            align="center"

                                          >
                                            <Checkbox
                                              disableRipple
                                              size="small"
                                              className="max-w-8 max-h-8"
                                              checked={selectedCandidates.includes(row.id)}
                                              onChange={() => handleCheckboxChange(row.id)}
                                            />
                                          </StyledTableCell>} */}
                                          <StyledTableCell
                                            component="td"
                                            scope="row"
                                            className="w-[20%] p-2"
                                          >
                                            <div className="w-full text-ellipsis overflow-hidden whitespace-nowrap text-[#353535] text-[13px] font-normal leading-5">
                                              {row.firstName +
                                                " " +
                                                row.lastName}
                                            </div>
                                          </StyledTableCell>
                                          <StyledTableCell
                                            className="w-[20%] p-2"
                                            component="td"
                                            scope="row"
                                          >
                                            <div className="w-full">
                                              {row.phoneNumber}
                                            </div>
                                          </StyledTableCell>
                                          <StyledTableCell
                                            className="w-[20%] p-2"
                                            component="td"
                                            scope="row"
                                          >
                                            <div className="w-full text-ellipsis overflow-hidden whitespace-nowrap flex justify-center text-[#353535] text-[13px] font-normal leading-5">
                                              {summary?.experience_year &&
                                              typeof summary?.experience_year ===
                                                "number"
                                                ? renderExperienceUI(
                                                    summary?.experience_year
                                                  )
                                                : summary?.experience_year ||
                                                  "N/A"}
                                            </div>
                                          </StyledTableCell>
                                          <StyledTableCell
                                            className="w-[20%] p-2"
                                            component="td"
                                            scope="row"
                                          >
                                            <div className="w-full text-[#353535] text-[13px] font-normal leading-5">
                                              {summary?.desired_salary &&
                                              typeof summary.desired_salary ===
                                                "number"
                                                ? formatToK(
                                                    summary.desired_salary
                                                  )
                                                : summary?.desired_salary ||
                                                  "N/A"}
                                            </div>
                                          </StyledTableCell>
                                          <StyledTableCell className="w-[20%] p-2">
                                            {/* category_reason */}
                                            <CustomTooltip
                                              title={
                                                summary?.category_reason || ""
                                              }
                                              arrow
                                              placement="top-end"
                                            >
                                              <div
                                                className={`${categoryChipColor(
                                                  row?.category,
                                                  row.callStatus
                                                )} font-medium py-1 px-4 rounded-[20px] w-fit m-auto capitalize`}
                                              >
                                                {renderCategoryValue(
                                                  row.category,
                                                  row.callStatus
                                                )}
                                              </div>
                                            </CustomTooltip>
                                          </StyledTableCell>
                                          <StyledTableCell className="w-[20%] p-2">
                                            <div className="flex items-center justify-center gap-2 px-4">
                                              <CustomTooltip
                                                title={statusDetail.tooltip}
                                                arrow
                                                placement="top-end"
                                              >
                                                <button
                                                  className={`${statusDetail.color} w-[150px] text-ellipsis overflow-hidden whitespace-nowrap border rounded-md border-rounded-sm flex items-center gap-2 px-3 py-2`}
                                                  onClick={(event) => {
                                                    event.preventDefault();
                                                    event.stopPropagation();
                                                    triggerAICall(row.id);
                                                  }}
                                                  disabled={
                                                    ![
                                                      "pending",
                                                      "busy",
                                                      "no-answer",
                                                      "failed",
                                                    ].includes(row.callStatus)
                                                  }
                                                >
                                                  <img
                                                    src={statusDetail.icon}
                                                    alt="phone-icon"
                                                  />
                                                  {statusDetail.text}
                                                </button>
                                              </CustomTooltip>
                                              <div
                                                className="flex cursor-pointer items-center justify-center p-2 border border-[#E5E5E5] rounded border-solid mr-auto w-8 h-8"
                                                onClick={(event) => {
                                                  event.preventDefault();
                                                  event.stopPropagation();
                                                  setMenuPopperRef(
                                                    event.currentTarget
                                                  );
                                                  setSelectedCandidate(row);
                                                }}
                                              >
                                                <img
                                                  src={HorizontalDots}
                                                  alt="horizontal-dots"
                                                  className="w-4 h-4"
                                                />
                                              </div>
                                              {[
                                                "in-progress",
                                                "ringing",
                                                "triggered",
                                                "triggering",
                                                "queue",
                                                "initiated",
                                                "ready-to-call",
                                              ].includes(row?.callStatus) && (
                                                <CustomTooltip
                                                  title={
                                                    isCancelCallDisabled
                                                      ? "Can't cancel call in Progress"
                                                      : "Cancel Call"
                                                  }
                                                  arrow
                                                  placement="top"
                                                >
                                                  <div
                                                    className={`flex cursor-pointer items-center justify-center p-2 border border-[#E5E5E5] rounded border-solid w-8 h-8`}
                                                    onClick={(event) => {
                                                      event.preventDefault();
                                                      event.stopPropagation();
                                                      !isCancelCallDisabled &&
                                                        handleCancelSingleCall(
                                                          row?.id
                                                        );
                                                    }}
                                                  >
                                                    <img
                                                      src={
                                                        isCancelCallDisabled
                                                          ? PhoneMissedGrayIcon
                                                          : PhoneMissedSecondaryIcon
                                                      }
                                                      alt="cancel-call"
                                                      className="w-4 h-4"
                                                    />
                                                  </div>
                                                </CustomTooltip>
                                              )}
                                            </div>
                                          </StyledTableCell>
                                        </StyledTableRow>
                                      );
                                    })}
                              </CustomizedTable>
                            )}
                          </div>
                        </Fragment>
                      ) : (
                        <AIChat />
                      )}
                    </Fragment>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
