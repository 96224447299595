import Skeleton from "@mui/material/Skeleton";
import React from "react";

export default function CandidateListSkeleton() {
  return (
    <div className="flex justify-between items-center border border-[#E5E5E5] rounded-md py-4 px-[20px]">
      <div className="flex gap-[10px]">
        <Skeleton
          variant="rectangular"
          width={40}
          height={40}
          className="bg-[#F2F2F2] rounded-full"
        />
        <div className="flex gap-1 flex-col">
          <Skeleton
            variant="rectangular"
            width={190}
            height={22}
            className="bg-[#F2F2F2] rounded "
          />
          <Skeleton
            variant="rectangular"
            width={190}
            height={18}
            className="bg-[#F2F2F2] rounded "
          />
        </div>
      </div>

      <div className="flex items-center">
        <Skeleton
          variant="rectangular"
          width={124}
          height={28}
          className="bg-[#F2F2F2] rounded mr-6"
        />
        <div className="flex items-center gap-9">
          <Skeleton
            variant="rectangular"
            width={124}
            height={28}
            className="bg-[#F2F2F2] rounded"
          />
          <Skeleton
            variant="rectangular"
            width={48}
            height={28}
            className="bg-[#F2F2F2] rounded "
          />
        </div>
      </div>
    </div>
  );
}
