import { forwardRef } from "react";
import { Dialog, DialogContent, Slide } from "@mui/material";
import { CrossIconWhite, GradientHeaderBg } from "assets";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InfoDialoadWithHeader = ({
  open,
  setOpen,
  handleSubmit,
  heading,
  infoDetail,
  icon,
  buttonText = "Okay",
  buttonClass,
  buttonWidth = "120px",
}) => {
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const handleOkayClick = () => {
    setOpen(false);
    handleSubmit();
  };
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "488px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent
          className="!p-0 relative font-hankengrotesk"
          style={{
            overflow: "hidden",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${GradientHeaderBg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            className="w-full h-full min-h-[44px] flex justify-between items-center bg-[#061025] bg-gradient-header pl-7 pr-4"
          >
            <div
              className={`text-lg font-medium leading-[22px] -tracking-[0.18px] text-white`}
            >
              {heading}
            </div>
            <div className="w-6 h-6 cursor-pointer" onClick={handleClose}>
              <img src={CrossIconWhite} alt="crossIcon" />
            </div>
          </div>
          <div className="!px-16 !py-[44px] max-w-[488px] flex flex-col items-center font-hankengrotesk">
            {icon && (
              <div className="w-[88px] h-[88px] bg-[rgb(246,249,253)] flex justify-center items-center rounded-full">
                <img src={icon} alt="icon" className="w-full h-full" />
              </div>
            )}
            <div className="w-full flex flex-col gap-3 mt-4">
              <div className="font-semibold text-[28px] leading-9 text-center text-[#353535]">
                {infoDetail.heading}
              </div>
              {infoDetail?.innerContent && (
                <div
                  className={`text-center ${
                    infoDetail?.innerContentClass
                      ? infoDetail?.innerContentClass
                      : ""
                  }`}
                >
                  {infoDetail?.innerContent}
                </div>
              )}
            </div>

            <button
              className={`btn h-12 flex justify-center items-center bg-[#061025] text-white font-medium py-[14px] text-center mt-8 rounded-lg w-full max-w-[${buttonWidth}] disabled:opacity-75 flex-1 px-[32px] font-hankengrotesk ${buttonClass}`}
              onClick={handleOkayClick}
            >
              {buttonText}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InfoDialoadWithHeader;
