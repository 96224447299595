import React, { Fragment, useEffect, useState } from "react";
import {
  UploadIcon,
  FileUploadSkeleton,
  CheckIconBlue,
  DeleteIconRed,
} from "assets";
import { resetUploadedFileValue } from "utils/common";

export default function FileUploadV2({ fileInfo, setFileInfo }) {
  const [error, setError] = useState("");
  const MAX_FILE_SIZE = 4 * 1024 * 1024;

  useEffect(() => {
    return () => {
      resetUploadedFileValue();
      setError("");
    };
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setError("File size should not exceed 4MB.");
        setFileInfo({ name: "", size: 0, base64: "" });
        return;
      }

      // Validate the file type
      const validExtensions = [".pdf"];
      const isValidFile = validExtensions.some((ext) =>
        file.name.toLowerCase().endsWith(ext)
      );

      if (!isValidFile) {
        setError("Only PDF files are allowed.");
        setFileInfo({ name: "", size: 0, base64: "" });
        return;
      }

      // Convert file to Base64
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1]; // Remove the prefix "data:<mime-type>;base64,"
        const sizeInKB = (file.size / 1024).toFixed(2);
        setFileInfo({
          name: file.name,
          size: sizeInKB,
          base64: base64String,
          file: file,
        });
        setError("");
      };

      reader.onerror = () => {
        setError("Failed to read the file.");
        setFileInfo({ name: "", size: 0, base64: "" });
      };

      reader.readAsDataURL(file);
    } else {
      setFileInfo({ name: "", size: 0, base64: "" });
    }
  };

  const handleFileRemove = () => {
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    setFileInfo({ name: "", size: 0 });
    setError("");
  };

  return (
    <Fragment>
      <div class="relative w-full h-full min-h-[192px] rounded-lg bg-dashed-line">
        <div className="absolute inset-[0px] rounded-lg p-[1px]">
          <label
            htmlFor="file-upload"
            className="h-full bg-[#F9F9F9] rounded-lg flex flex-col justify-center items-center cursor-pointer"
          >
            <input
              id="file-upload"
              type="file"
              accept=".pdf"
              className="hidden"
              onChange={handleFileChange}
            />
            {fileInfo.name ? (
              <div className="w-full h-full flex justify-center items-center relative z-10">
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-0">
                  <img src={FileUploadSkeleton} alt="File Upload Skeleton" />
                </div>
                <div className="flex justify-between items-center bg-[#E8EDF6] py-[14px] px-3 w-full max-w-[344px] rounded-lg relative z-10">
                  <div className="flex items-center w-[calc(100%_-_38px)] gap-[10px]">
                    <img src={CheckIconBlue} alt="CheckIconBlue" />
                    <span className="w-[calc(100%_-_24px)] text-sm text-gray-600 text-ellipsis whitespace-nowrap overflow-hidden">
                      Selected file: {fileInfo.name} ({fileInfo.size} KB)
                    </span>
                  </div>
                  <div
                    className="w-7 h-7 flex justify-center items-center"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click event from triggering the file input
                      e.preventDefault();
                      handleFileRemove();
                    }}
                  >
                    <img
                      src={DeleteIconRed}
                      alt="CancelIcon"
                      className="w-full h-full"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center">
                <img src={UploadIcon} alt="UploadIcon" width={20} height={20} />
                <span className="block text-sm font-medium text-[#121212] mt-2">
                  Drag & Drop your file here or Browse file
                </span>
                <p className="text-xs text-[#353535]">
                  (File must be in .PDF and size should not exceed 4MB)
                </p>
              </div>
            )}
          </label>
        </div>
      </div>
      {error && (
        <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
          {error}
        </span>
      )}
    </Fragment>
  );
}
