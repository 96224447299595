import React, { useEffect, useRef, useState } from "react";
import JobTypeDialog from "dialogs/JobType";
import NewCompanyCandidateDialog from "dialogs/NewCompanyCandidate";
import {
  CompletedGreen,
  JobHuntIcon,
  NoSearchResult,
  PlusIcon,
  SearchBlue,
  WarningInfoIcon,
} from "assets";
import InfoDialogV2 from "dialogs/InfoDialogV2";
import { debounce } from "utils/common";
import _ from "lodash";
import AddPhoneNumber from "dialogs/AddPhoneNumber";
import CircularLoader from "component/CircularLoader";
import CandidateListSkeleton from "pages/Overview/candidateListSkeleton";
import CompanyList from "./companyList";
import { createCompanyCandidate } from "services/Company";

export default function OverView({ auth }) {
  const [jobTabValue, setJobTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [creationLoader, setCreationLoader] = useState(false);
  const [jobTypeDialog, setJobTypeDialog] = useState(false);
  const [infoDialoag, setInfoDialoag] = useState(false);
  const [trialEndingDialog, setTrialEndingDialog] = useState(false);
  const [addPhoneDialog, setAddPhoneDialog] = useState(false);

  const [jobType, setJobType] = useState("");
  const [listData, setListData] = useState([
    {
      id: "105",
      title: "test",
      successfulCallsCount: 232,
      failedCallsCount: 3432,
      candidateCount: 122,
    },
    {
      id: "105",
      title: "test",
      successfulCallsCount: 232,
      failedCallsCount: 3432,
      candidateCount: 122,
    },
    {
      id: "105",
      title: "test",
      successfulCallsCount: 232,
      failedCallsCount: 3432,
      candidateCount: 122,
    },
    {
      id: "105",
      title: "test",
      successfulCallsCount: 232,
      failedCallsCount: 3432,
      candidateCount: 122,
    },
    {
      id: "105",
      title: "test",
      successfulCallsCount: 232,
      failedCallsCount: 3432,
      candidateCount: 122,
    },
    {
      id: "105",
      title: "test",
      successfulCallsCount: 232,
      failedCallsCount: 3432,
      candidateCount: 122,
    },
    {
      id: "105",
      title: "test",
      successfulCallsCount: 232,
      failedCallsCount: 3432,
      candidateCount: 122,
    },
    {
      id: "105",
      title: "test",
      successfulCallsCount: 232,
      failedCallsCount: 3432,
      candidateCount: 122,
    },
    {
      id: "105",
      title: "test",
      successfulCallsCount: 232,
      failedCallsCount: 3432,
      candidateCount: 122,
    },
    {
      id: "105",
      title: "test",
      successfulCallsCount: 232,
      failedCallsCount: 3432,
      candidateCount: 122,
    },
    {
      id: "105",
      title: "test",
      successfulCallsCount: 232,
      failedCallsCount: 3432,
      candidateCount: 122,
    },
  ]);
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef(searchValue);
  const jobTabRef = useRef(jobTabValue);

  const callApi = async (
    value = searchValue,
    slient = false,
    status = jobTabValue
  ) => {
    if (!slient) setLoading(true);
    // const result = await getJobs(value, status === 0 ? "active" : "archived");
    // setListData(result?.data?.result);
    if (!slient) setLoading(false);
  };

  useEffect(() => {
    if (
      (_.get(auth, "userData.UserData.trialDays", 0) === 1 ||
        _.get(auth, "userData.UserData.trialDays", 0) === 2) &&
      !localStorage.getItem("trialEndingDialogState") &&
      !_.get(auth, "userData.UserData.phone", "")
    ) {
      setTrialEndingDialog(true);
      localStorage.setItem("trialEndingDialogState", "true");
    }
  }, [_.get(auth, "userData.UserData.trialDays", 0)]);

  useEffect(() => {
    callApi();
  }, [jobTabValue]);

  useEffect(() => {
    callApi();
    const intervalId = setInterval(() => {
      callApi(searchRef.current, true, jobTabRef.current);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const debouncedSearch = debounce(callApi, 500);

  const handleSearchJob = (value) => {
    setSearchValue(value);
    searchRef.current = value;
    debouncedSearch(value);
  };

  const handleCreateCandidate = async (payload) => {
    setJobType(false);
    setCreationLoader(true);
    const result = await createCompanyCandidate(payload);
    setCreationLoader(false);
    if (result.data) {
      setInfoDialoag(true);
      await callApi();
      setLoading(false);
    }
  };

  const renderEmptyScreen = () => {
    return (
      <div
        className={`h-full w-full border border-[#E5E5E5] rounded-lg flex flex-col justify-center items-center gap-6 p-4`}
      >
        <div
          className={`w-full max-w-[525px] mx-auto flex flex-col justify-center items-center gap-[6px] text-center`}
        >
          <img
            src={searchValue ? NoSearchResult : JobHuntIcon}
            alt="JobHunt"
            className="max-w-full h-auto"
          />
          <p className="m-0 text-center text-xl sm:text-2xl leading-7 sm:leading-8 text-[#2E2E2E] font-medium">
            {jobTabValue === 1
              ? "There are no archived jobs yet."
              : searchValue
              ? "No Result Found"
              : `Hey ${
                  auth?.userData?.FirstName + " " + auth?.userData?.LastName
                } Looks like you're new here.`}
          </p>
          <p className="text-[#2E2E2E] text-[16px] leading-5 font-light max-w-[310px]">
            {searchValue
              ? "We couldn't find any results based on your search, please try again."
              : " Click below to connect your candidates with potential employers!"}
          </p>
        </div>
        {searchValue ? (
          <button
            className="bg-[#061025] text-white pl-5 pr-7 py-[14px] rounded-md text-sm sm:text-base"
            onClick={() => callApi()}
          >
            Refresh
          </button>
        ) : (
          <button
            className="font-semibold max-h-[48px] mt-4 bg-[#061025] flex items-center text-white pl-5 pr-7 py-[14px] rounded-md text-sm sm:text-base whitespace-nowrap"
            onClick={() => setJobType("candidates")}
          >
            <img src={PlusIcon} alt="plus-icon" className="w-5 h-5 mr-[6px]" />
            Add Exployer
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="py-4 sm:py-6 lg:py-8 px-4 sm:px-6 lg:px-10 h-[calc(100vh_-_75px)]">
      <div
        className={`h-full bg-[#FDFEFF] border border-[#E5E5E5] rounded-xl overflow-hidden`}
      >
        {creationLoader && <CircularLoader />}
        {jobTypeDialog && (
          <JobTypeDialog
            open={jobTypeDialog}
            setOpen={setJobTypeDialog}
            handleSelectJobType={(jobType) => {
              setJobTypeDialog(false);
              setJobType(jobType);
            }}
          />
        )}
        {jobType && (
          <NewCompanyCandidateDialog
            open={jobType ? true : false}
            setOpen={(open) => setJobType(open ? jobType : "")}
            handleCreateCandidate={handleCreateCandidate}
          />
        )}
        {infoDialoag && (
          <InfoDialogV2
            open={infoDialoag}
            setOpen={setInfoDialoag}
            infoDetail={{
              heading: "Posted Successfully!",
              innerContent: "",
            }}
            handleSubmit={() => {}}
            icon={CompletedGreen}
          />
        )}
        {trialEndingDialog && (
          <InfoDialogV2
            open={trialEndingDialog}
            setOpen={setTrialEndingDialog}
            infoDetail={{
              heading:
                "We noticed you haven’t put your mobile number. Please add for faster communication and verification.",
              innerContent: "",
            }}
            handleSubmit={() => setAddPhoneDialog(true)}
            buttonText="Add Phone Number"
            buttonWidth="150px"
            icon={WarningInfoIcon}
          />
        )}
        <AddPhoneNumber
          open={addPhoneDialog}
          setOpen={setAddPhoneDialog}
          handleSubmit={() => {}}
        />
        <div
          className={`w-full h-full flex flex-col bg-[#FDFEFF] rounded-xl ${
            listData.length > 0 ? "p-0" : "px-8 py-7"
          }`}
        >
          <div
            // className="flex flex-col sm:flex-row justify-between items-center mb-8 pt-7 px-8 gap-4 flex-wrap"
            className={`flex flex-col sm:flex-row justify-between items-center mb-8 gap-4 ${
              listData.length > 0 ? "px-8 pt-7" : "p-0"
            }`}
          >
            <h6
              className={`m-0 text-2xl sm:text-[28px] font-semibold leading-8 sm:leading-9 text-[#353535]`}
            >
              Employers
            </h6>
            <div
              className={`w-full max-w-[352px] flex items-center gap-1 border-[1.2px] border-[#e5e5e5] rounded-lg px-4 sm:px-[68px] py-2 sm:py-[14px] ${
                listData && listData?.length === 0
                  ? "opacity-40"
                  : "opacity-100"
              }`}
            >
              <img src={SearchBlue} alt="search-icon" className="w-5 h-5" />
              <input
                type="text"
                placeholder="Search for Specific Companies"
                className={`w-full min-w-[265px] text-sm leading-5 font-normal text-[#121212] bg-transparent border-none focus-visible:outline-none`}
                onChange={(event) =>
                  listData &&
                  listData?.length > 0 &&
                  handleSearchJob(event.target.value)
                }
                value={searchValue}
              />
            </div>
            <button
              className={`bg-[#061025] text-white pl-[14px] h-12 pr-[21px] py-[14px] rounded-lg whitespace-nowrap text-sm sm:text-base font-semibold leading-[19px] flex items-center`}
              onClick={() => setJobType("candidates")}
            >
              <img
                src={PlusIcon}
                alt="plus-icon"
                className="w-5 h-5 mr-[6px]"
              />
              Add Employer
            </button>
          </div>
          {loading ? (
            <div className="flex gap-4 flex-col px-4 sm:px-6">
              {[0, 1, 2, 3, 4].map((_, index) => (
                <CandidateListSkeleton />
              ))}
            </div>
          ) : (
            <div className={`p-0 h-[calc(100%_-_72px)] flex flex-col gap-4`}>
              <div
                className={`h-[calc(100%_-_29px)] flex flex-col gap-4 overflow-auto scrollbar  ${
                  listData && listData?.length === 0
                    ? "pb-0 px-0"
                    : "px-8 pb-12"
                }`}
              >
                {listData && listData?.length === 0
                  ? renderEmptyScreen()
                  : listData?.map((job) => <CompanyList company={job} />)}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
