const URI = Object.freeze(
  "https://0pmu96psye.execute-api.eu-west-1.amazonaws.com/dev/v1"
);
// const URI = Object.freeze("http://localhost:7000/v1");
export const APIURI = URI;

export const endPoints = Object.freeze({
  auth: {
    signup: URI + "/auth/signup",
    login: URI + "/auth/login",
    getUser: URI + "/auth/getUser",
    updateCompanyName: URI + "/auth/change-company-name",
    changePassword: URI + "/auth/change-password",
    updatePassword: URI + "/auth/update-password",
    updatePhoneNumber: URI + "/auth/add-phone-number",
    sendResetPasswordOtp: URI + "/auth/send-reset-password-otp",
    sendOtp: URI + "/auth/sendOtp",
    validateOtp: URI + "/auth/validate-otp",
    deleteAccount: URI + "/auth/delete-account",
  },
  subscription: {
    fetchSubscription: URI + "/subscription",
    createCheckoutSession: URI + "/subscription/create-checkout-session",
  },
  job: {
    getJobs: URI + "/job",
    getJobDetail: URI + "/job/:job_id",
    createJob: URI + "/job",
    updateJob: URI + "/job/:jobId",
    updateJobStatus: URI + "/job/:jobId/status",
    getJob: URI + "/job/:jobId",
    getCandidates: URI + "/job/:job_id/candidates",
    getCalls: URI + "/job/:job_id/calls",
    createCandidates: URI + "/job/:job_id/candidates",
    createCandidate: URI + "/job/:job_id/candidate",
    updateCandidate: URI + "/job/:job_id/candidate/:candidate_id/category",
    deleteCandidate: URI + "/job/:job_id/candidate/:candidate_id",
    updateCandidateNotes: URI + "/job/:job_id/candidate/:candidate_id/notes",
    executeAICall: URI + "/job/:job_id/candidate/:candidate_id/dial-call",
    cancelSingleCall: URI + "/job/:job_id/candidate/:candidate_id/cancel-call",
    cancelAllCalls: URI + "/job/:job_id/cancel-all-calls",
    dialAllCalls: URI + "/job/:job_id/dial-all-calls",
  },
  company: {
    getCompaniesCandidates: URI + "/companyCandidate",
    getCompanyCandidateDetail: URI + "/companyCandidate/:candidate_id",
    createCompanyCandidate: URI + "/companyCandidate",
    updateCompanyCandidate: URI + "/companyCandidate/:candidate_id",
    updateCompanyCandidateStatus: URI + "/companyCandidate/:candidate_id/status",
    getCompanies: URI + "/companyCandidate/:candidate_id/companies",
    getCalls: URI + "/companyCandidate/:candidate_id/calls",
    createCandidateCompanies: URI + "/companyCandidate/:candidate_id/companies",
    createCandidateCompany: URI + "/companyCandidate/:candidate_id/company",
    updateCompany: URI + "/companyCandidate/:candidate_id/company/:company_id/category",  
    deleteCompany: URI + "/companyCandidate/:candidate_id/company/:company_id",
    updateCompanyNotes: URI + "/companyCandidate/:candidate_id/company/:company_id/notes",
    executeAICall: URI + "/companyCandidate/:candidate_id/company/:company_id/dial-call",
    cancelSingleCall: URI + "/companyCandidate/:candidate_id/company/:company_id/cancel-call",
    cancelAllCalls: URI + "/companyCandidate/:candidate_id/cancel-all-calls",
    dialAllCalls: URI + "/companyCandidate/:candidate_id/dial-all-calls",
  },
  AIChat: {
    createChat: URI + "/chats/job/:jobId",
    getAllChat: URI + "/chats/job/:jobId",
    updateChatTitle: URI + "/chats",
    deleteChat: URI + "/chats/delete/:ChatId",
    getChatMessages: URI + "/chats/:ChatId/message",
    addChatMessage: URI + "/chats/message",
    createCompanyCandidateChat: URI + "/chats/companyCandidate/:candidate_id",
    getAllCompanyCandidateChat: URI + "/chats/companyCandidate/:candidate_id",
    updateCompanyCandidateChatTitle: URI + "/chats",
    deleteCompanyCandidateChat: URI + "/chats/delete/:ChatId",
    getCompanyCandidateChatMessages: URI + "/chats/:ChatId/message",
    addCompanyCandidateChatMessage: URI + "/chats/message",
  },
  feedback: {
    sendFeedback: URI + "/auth/feedback",
  },
  admin: {
    getUnderReviewUsers: URI + "/auth/admin/get-under-review-users",
    getTrialEligibleUsers: URI + "/auth/admin/get-trial-eligible-users",
    approvedUser: URI + "/auth/admin/approve-user",
    approvedUserTrial: URI + "/auth/admin/approve-user-trial",
  },
});
