import { forwardRef } from "react";
import { Dialog, DialogContent, Slide } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Loader from "component/Loader";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CircularLoader = () => {
  return (
    <>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "278px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="!px-[52px] !py-[44px] relative">
          <div className="m-auto h-full flex justify-center items-center z-40">
            <div className="text-center flex flex-col items-center z-40 gap-[34px]">
              <CircularProgress
                size={52}
                sx={(theme) => ({
                  color: "#061025",
                })}
              />
              <div className="font-semibold text-[28px] leading-9 text-[#353535]">
                Loading..
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CircularLoader;
