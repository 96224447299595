import { forwardRef, useMemo } from "react";
import { Dialog, DialogContent, DialogContentText, Slide } from "@mui/material";
import { CrossIcon } from "assets";
import { formatToK } from "utils/common";
import moment from "moment";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewCandidateDetail = (props) => {
  const { open, setOpen, selectedCandidateDetail } = props;
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const renderText = (label, text, index) => {
    return (
      <div
        className={`flex justify-between py-4 ${
          index !== companyDetail.length - 1
            ? "border-b-[1px] border-[#E5E5E5]"
            : ""
        }`}
        key={index}
      >
        <p className="text-[#1F1F1F] opacity-60 text-[16px] leading-4 font-normal">
          {label}
        </p>
        <p className="text-[#393939] font-semibold text-[15px] leading-5">
          {text}
        </p>
      </div>
    );
  };

  const companyDetail = useMemo(() => {
    return [
      { label: "Candidate Name", value: selectedCandidateDetail?.name },
      { label: "Candidate Position", value: selectedCandidateDetail?.position },
      {
        label: "Candidate ID",
        value: `#${selectedCandidateDetail?.id}`,
      },
      {
        label: "Posted",
        value: moment(selectedCandidateDetail?.createdAt).format("DD/MM/YY"),
      },

      {
        label: "Number of Calls",
        value:
          Number(selectedCandidateDetail?.failedCallsCount || 0) +
          Number(selectedCandidateDetail?.successfulCallsCount || 0),
      },
    ];
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "520px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent className="!pt-[52px] !pb-[64px] !px-[80px] relative">
          <DialogContentText id="alert-dialog-slide-description">
            <img
              src={CrossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer absolute top-4 right-4"
              onClick={handleClose}
            />
            <div className="flex flex-col justify-start items-start gap-3 max-w-[360px] w-full mx-auto font-hankengrotesk scrollbar">
              <div className="w-full text-[#353535] font-semibold text-[28px] leading-9 text-center">
                Candidate Details
              </div>
              <div className="w-full">
                {companyDetail.map((detail, index) => {
                  return renderText(detail.label, detail.value, index);
                })}
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewCandidateDetail;
