import { endPoints } from "services/endPoints";
import axiosInstance from "utils/axiosInstance";

export const createChat = async (jobId, message) => {
  return await axiosInstance.post(
    `${endPoints.AIChat.createChat.replace(":jobId", jobId)}`,
    {
      Message: message,
    }
  );
};

export const getAllChat = async (jobId) => {
  return await axiosInstance.get(
    `${endPoints.AIChat.getAllChat.replace(":jobId", jobId)}`
  );
};

export const updateChatTitle = async (title, chatId) => {
  return await axiosInstance.put(`${endPoints.AIChat.updateChatTitle}`, {
    Title: title,
    ChatId: chatId,
  });
};

export const deleteChat = async (chatId) => {
  return await axiosInstance.delete(
    `${endPoints.AIChat.deleteChat.replace(":ChatId", chatId)}`
  );
};

export const getChatMessages = async (chatId) => {
  return await axiosInstance.get(
    `${endPoints.AIChat.getChatMessages.replace(":ChatId", chatId)}`
  );
};

export const addChatMessage = async (message, type, chatId) => {
  return await axiosInstance.put(`${endPoints.AIChat.addChatMessage}`, {
    Message: message,
    Type: type,
    ChatId: chatId,
  });
};

export const createCompanyCandidateChat = async (candidate_id, message) => {
  return await axiosInstance.post(
    `${endPoints.AIChat.createCompanyCandidateChat.replace(
      ":candidate_id",
      candidate_id
    )}`,
    {
      Message: message,
    }
  );
};

export const getAllCompanyCandidateChat = async (candidate_id) => {
  return await axiosInstance.get(
    `${endPoints.AIChat.getAllCompanyCandidateChat.replace(
      ":candidate_id",
      candidate_id
    )}`
  );
};

export const updateCompanyCandidateChatTitle = async (title, chatId) => {
  return await axiosInstance.put(
    `${endPoints.AIChat.updateCompanyCandidateChatTitle}`,
    {
      Title: title,
      ChatId: chatId,
    }
  );
};

export const deleteCompanyCandidateChat = async (chatId) => {
  return await axiosInstance.delete(
    `${endPoints.AIChat.deleteCompanyCandidateChat.replace(":ChatId", chatId)}`
  );
};

export const getCompanyCandidateChatMessages = async (chatId) => {
  return await axiosInstance.get(
    `${endPoints.AIChat.getCompanyCandidateChatMessages.replace(
      ":ChatId",
      chatId
    )}`
  );
};

export const addCompanyCandidateChatMessage = async (message, type, chatId) => {
  return await axiosInstance.put(
    `${endPoints.AIChat.addCompanyCandidateChatMessage}`,
    {
      Message: message,
      Type: type,
      ChatId: chatId,
    }
  );
};
