import { forwardRef, Fragment, useEffect, useState } from "react";
import { Dialog, DialogContent, Slide } from "@mui/material";
import { CrossIcon, WarningInfoIcon } from "assets";
import { createCandidateCompanies } from "services/Company";
import InfoDialog from "./InfoDialog";
import { handleDownloadSampleCompanyCsv, handleDownloadSampleCsv } from "utils/common";
import CustomizedTable, {
  StyledTableCell,
  StyledTableRow,
} from "component/Table";
import UploadedCompaniesEmptyScreen from "component/EmptyScreen/UploadedCompaniesEmptyScreen";
import FileUploadV2 from "component/FileUploadV2";
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UploadCompanies({
  open,
  setOpen,
  candidateId,
  handleCallBack,
}) {
  const [loading, setLoading] = useState(false);
  const [uploadedCandidates, setUploadedCompanies] = useState([]);
  const [fileInfo, setFileInfo] = useState({ name: "", size: 0 });
  const [manualUploadDetail, setManualUploadDetail] = useState({
    name: "",
    phoneNumber: "+44",
    location: ""
  });
  const [validationErrors, setValidationErrors] = useState({
    fileInfo: "",
    name: "",
    phoneNumber: "",
    location: ""
  });
  const [infoDialog, setInfoDialog] = useState(false);
  const [duplicates, setDuplicates] = useState(0);
  const [isManualUpload, setIsManualUpload] = useState(false);
  const [manuallyUploadedList, setManuallyUploadedList] = useState([]);
  const [manualCandidateAlreadyUpload, setManualCandidateAlreadyUpload] =
    useState(false);

  const manualUploadTableColumn = [
    { label: "Company name", field: "name" },
    { label: "Location", field: "location" },
    { label: "Phone number", field: "phoneNumber" },
  ];
  const errorMsg = {
    fileInfo: "CSV or Excel file must be uploaded",
    name: "Company name is required",
    location: "Location is required",
    phoneNumber: "Phone number is required",
  };
  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const validateSubmit = () => {
    const errors = {};
    if (isManualUpload) {
      if (!manualUploadDetail.name) {
        errors.name = errorMsg.name;
      }
      if (!manualUploadDetail.location) {
        errors.location = errorMsg.location;
      }

      const formattedValue = manualUploadDetail.phoneNumber.replace(/\D/g, "");
      if (formattedValue.includes("44") && formattedValue.length === 2) {
        errors.phoneNumber = errorMsg.phoneNumber;
      } else if (formattedValue.length !== 12) {
        // phone numbers have 44 + 10 digits
        errors.phoneNumber = "Please enter a valid UK phone number";
      }
    } else {
      if (!fileInfo.name) {
        errors.fileInfo = errorMsg.fileInfo;
      }
    }
    return errors;
  };

  useEffect(() => {
    if (open) {
      resetState();
      setIsManualUpload(false);
    }
  }, [open]);

  const resetState = () => {
    setLoading(false);
    setUploadedCompanies([]);
    setFileInfo({ name: "", size: 0 });
    setValidationErrors({
      fileInfo: "",
      name: "",
      location: "",
      phoneNumber: "",
    });
    setManualUploadDetail({ name: "", location: "", phoneNumber: "+44" });
    setManuallyUploadedList([]);
    setManualCandidateAlreadyUpload(false);
  };

  const handleSubmit = async () => {
    let errors = validateSubmit();

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      const uploadedData = isManualUpload
        ? [manualUploadDetail]
        : uploadedCandidates;
      if (uploadedData.length > 0) {
        setLoading(true);
        const result = await createCandidateCompanies(candidateId, uploadedData);

        setLoading(false);
        !isManualUpload && setOpen(false);
        handleCallBack();
        if (result?.data?.result?.skipped) {
          !isManualUpload && setInfoDialog(true);
          isManualUpload && setManualCandidateAlreadyUpload(true);
          setDuplicates(result?.data?.result?.skipped);
        } else if (result?.data?.result?.created && isManualUpload) {
          setManuallyUploadedList([
            ...manuallyUploadedList,
            manualUploadDetail,
          ]);
          setManualUploadDetail({
            name: "",
            location: "",
            phoneNumber: "+44",
          });
        }
      }
    }
  };

  const handleToggleChange = () => {
    setIsManualUpload(!isManualUpload);
    resetState();
  };

  const handleManualDetailChange = (fieldName, value) => {
    const newValues = { ...manualUploadDetail, [fieldName]: value };
    setManualUploadDetail(newValues);

    // Real-time validation for changed field
    let newErrors = { ...validationErrors };
    if (fieldName === "phoneNumber") {
      const formattedValue = value.replace(/\D/g, "");
      if (formattedValue.includes("44") && formattedValue.length === 2) {
        newErrors.phoneNumber = errorMsg.phoneNumber;
      } else if (formattedValue.length !== 12) {
        // phone numbers have 44 + 10 digits
        newErrors.phoneNumber = "Please enter a valid UK phone number";
      } else {
        delete newErrors.phoneNumber;
      }
    } else {
      if (!value) {
        newErrors[fieldName] = `${errorMsg[fieldName]}`;
      } else {
        delete newErrors[fieldName];
      }
    }

    setValidationErrors(newErrors);
  };

  const renderInputField = (name, placeholder, label, type = "text") => (
    <div className="flex flex-col gap-1">
      <label className="text-[#1F1F1F] text-[13px] font-normal leading-5 -tracking-[0.13px] ">
        {label}
      </label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={manualUploadDetail[name]}
        onBlur={() =>
          handleManualDetailChange(name, manualUploadDetail?.[name]?.trim())
        }
        onChange={(e) => {
          // not need to show any error when try to onchanging after submitting already uploaded candidate detail
          setManualCandidateAlreadyUpload(false);
          const inputValue = e.target.value;
          if (name === "phoneNumber") {
            // Ensure +44 prefix remains intact and allow only digits after +44
            const phoneValue = "+44" + inputValue.slice(3).replace(/\D/g, "");
            handleManualDetailChange(name, phoneValue);
          } else {
            handleManualDetailChange(name, inputValue);
          }
        }}
        className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none"
      />
      {validationErrors[name] && (
        <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
          {validationErrors[name]}
        </div>
      )}
    </div>
  );

  return (
    <>
      {infoDialog && duplicates && (
        <InfoDialog
          open={infoDialog}
          setOpen={setInfoDialog}
          infoDetail={{
            heading: `We have Detected and Removed ${duplicates} Duplicates`,
            innerContent: "",
          }}
          handleSubmit={() => {}}
          icon={WarningInfoIcon}
        />
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "896px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent
          className={`relative font-hankengrotesk scrollbar min-h-[578px] max-h-[578px] !py-[52px] lg:!px-[${
            isManualUpload ? "52px" : "197px"
          }]`}
        >
          <div
            className="absolute top-4 right-4 cursor-pointer"
            onClick={handleClose}
          >
            <img src={CrossIcon} alt="crossIcon" />
          </div>
          <div
            className={`rounded-md ${
              isManualUpload ? "max-w-full" : "max-w-[502px]"
            } m-auto`}
          >
            <div className="flex justify-between items-center max-w-[500px] mx-auto">
              <div className="font-semibold text-[28px] text-center">
                Upload Companies
              </div>

              <div className="flex items-center gap-[10px]">
                <label
                  htmlFor="toggle"
                  className="text-[#121212] opacity-70 text-[13px] leading-4 font-semibold"
                >
                  Paste Manually
                </label>
                <input
                  type="checkbox"
                  id="toggle"
                  className="toggle-checkbox hidden"
                  checked={isManualUpload}
                  onChange={handleToggleChange}
                />
                <div
                  className={`relative w-10 h-5 rounded-full flex items-center transition duration-200 ease-in select-none cursor-pointer ${
                    isManualUpload ? "bg-blue-600" : "bg-gray-300"
                  }`}
                  onClick={handleToggleChange}
                >
                  <div
                    className={`w-4 h-4 rounded-full bg-white transition duration-200 ease-in ${
                      isManualUpload
                        ? "translate-x-[22px]"
                        : "translate-x-[2px]"
                    }`}
                  ></div>
                </div>
              </div>
            </div>

            {isManualUpload ? (
              <div className="flex justify-between items-start gap-4 min-h-[408px]">
                <div className="flex flex-col flex-1 w-1/2 mt-[24px]  ">
                  <div className="flex flex-col gap-[18px] mb-[52px] ">
                    {renderInputField(
                      "name",
                      "Ex GreenCode",
                      "Company Name",
                      "text"
                    )}
                    {renderInputField(
                      "location",
                      "Baker St, London, UK",
                      "Location",
                      "text"
                    )}
                    {renderInputField(
                      "phoneNumber",
                      "Ex +44 7123 456 789",
                      "Phone Number",
                      "phone"
                    )}
                    {manualCandidateAlreadyUpload &&
                      duplicates > 0 && ( // found like that record already upload so not be able to manual uploaded again
                        <div className="w-full text-sm font-medium text-[#FF352A]">
                          Company has already been uploaded
                        </div>
                      )}
                  </div>
                  <div className="w-full flex justify-between items-center gap-3 sm:gap-0">
                    <button
                      className={`btn h-[50px] bg-white flex items-center border-custom-primary-dark border text-black border-purple font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg`}
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn h-[50px] flex items-center bg-custom-primary-dark text-white font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg  sm:ml-4 disabled:opacity-75"
                      onClick={() => handleSubmit()}
                      disabled={loading}
                    >
                      {loading ? "Uploading..." : "Upload"}
                    </button>
                  </div>
                </div>
                <div className="mt-[24px] flex-1 w-1/2 flex flex-col gap-[5px] max-h-[382px]">
                  <h2 className="text-[rgb(31,31,31)] text-base leading-5 font-semibold">
                    Manually Uploaded Companies
                  </h2>
                  {manuallyUploadedList && manuallyUploadedList.length > 0 ? (
                    <div className="overflow-auto">
                      <CustomizedTable
                        columns={manualUploadTableColumn}
                        isShowPagination={false}
                        renderFrom="uploadCandidates"
                      >
                        {manuallyUploadedList
                          .slice()
                          .reverse()
                          .map((row, index) => {
                            return (
                              <StyledTableRow key={index}>
                                {manualUploadTableColumn.map(
                                  (column, index) => {
                                    return (
                                      <StyledTableCell
                                        component="td"
                                        scope="row"
                                        className="w-[20%] p-2"
                                        key={index}
                                      >
                                        <div className="w-full text-ellipsis overflow-hidden whitespace-nowrap text-[#353535] text-[13px] font-normal leading-5 max-w-[250px]">
                                          {row[column.field] || "--"}
                                        </div>
                                      </StyledTableCell>
                                    );
                                  }
                                )}
                              </StyledTableRow>
                            );
                            // Add proper empty screen over here
                          })}
                      </CustomizedTable>
                    </div>
                  ) : (
                    <UploadedCompaniesEmptyScreen
                      columns={manualUploadTableColumn}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div>
                <div className="mb-[52px] mt-[24px] flex flex-col">
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-1">
                      <h2 className="text-[rgb(31,31,31)] text-base leading-[22px] font-semibold">
                        Upload CSV
                      </h2>
                      <h3 className="text-[rgb(31,31,31)] text-[13px] leading-5 font-light tracking-[-0.01em]">
                        Please upload a CSV file with the companies names and
                        contact numbers.{" "}
                        <span
                          className="text-blue-500 cursor-pointer"
                          onClick={handleDownloadSampleCompanyCsv}
                        >
                          Click here
                        </span>{" "}
                        to see a template of what it should look like.
                      </h3>
                    </div>
                    <div className="flex flex-col gap-3">
                      <FileUploadV2
                        fileInfo={fileInfo}
                        setFileInfo={(file) => {
                          setFileInfo(file);
                          // Clear file validation error if file is uploaded
                          if (file && file.name) {
                            setValidationErrors((prevErrors) => ({
                              ...prevErrors,
                              fileInfo: null, // Clear file error
                            }));
                          }
                        }}
                        setUploadedDetails={setUploadedCompanies}
                      />
                      {validationErrors.fileInfo && (
                        <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                          {validationErrors.fileInfo}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="w-full flex justify-between items-center gap-3 sm:gap-0">
                  <button
                    className={`btn h-[50px] bg-white flex items-center border-custom-primary-dark border text-black border-purple font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg`}
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn h-[50px] flex items-center bg-custom-primary-dark text-white font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg  sm:ml-4 disabled:opacity-75"
                    onClick={() => handleSubmit()}
                    disabled={loading}
                  >
                    {loading ? "Uploading..." : "Upload"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
