import React, { Fragment, useEffect, useState } from "react";
import Papa from "papaparse";
import { CrossIcon, UploadIcon, FileUploadIcon } from "assets";
import { resetUploadedFileValue } from "utils/common";
import * as XLSX from "xlsx";

export default function FileUpload({
  fileInfo,
  setFileInfo,
  setUploadedCandidates,
}) {
  const [error, setError] = useState("");

  useEffect(() => {
    return () => {
      resetUploadedFileValue();
      setError("");
    };
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate the file type
      const validExtensions = [".csv", ".xlsx", ".xls"];
      const isValidFile = validExtensions.some((ext) =>
        file.name.toLowerCase().endsWith(ext)
      );

      if (!isValidFile) {
        setError("Only CSV and Excel files (.csv, .xlsx, .xls) are allowed");
        setFileInfo({ name: "", size: 0 });
        return;
      }

      if (file.name.endsWith(".csv")) {
        handleCSVFile(file);
      } else {
        handleExcelFile(file);
      }

      // // Parse the CSV file
      // Papa.parse(file, {
      //   header: true,
      //   complete: (results) => {},
      //   error: () => {
      //     setError("There was an error parsing the CSV file");
      //     setFileInfo({ name: "", size: 0 });
      //   },
      // });
    } else {
      setFileInfo({ name: "", size: 0 });
    }
  };

  const handleExcelFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet);

        processFileData(jsonData, file, "excel");
      } catch (error) {
        setError("There was an error parsing the Excel file");
        setFileInfo({ name: "", size: 0 });
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleCSVFile = (file) => {
    Papa.parse(file, {
      header: true,
      complete: (results) => {
        processFileData(results.data, file, "csv");
      },
      error: () => {
        setError("There was an error parsing the CSV file");
        setFileInfo({ name: "", size: 0 });
      },
    });
  };

  const processFileData = (data, file, type = "csv") => {
    // const headers = results.meta.fields.map((header) =>
    //   header.trim().toLowerCase()
    // );
    const headers = Object.keys(data[0] || {}).map((header) =>
      header.trim().toLowerCase()
    );
    const requiredHeaders = ["firstname", "lastname", "phonenumber"];
    
    // Check if all required headers are present
    const missingHeaders = requiredHeaders.filter(
      (header) => !headers.includes(header)
    );

    if (missingHeaders.length > 0) {
      if (type === "csv") {
        setError(
          `CSV file is missing the following required headers: ${missingHeaders.join(
            ", "
          )}`
        );
      } else {
        setError(
          `Excel file (Sheet 1) is missing the following required headers: ${missingHeaders.join(
            ", "
          )}. Please ensure all required headers and data are present in the first sheet.`
        );
      }
      setFileInfo({ name: "", size: 0 });
    } else {
      const sizeInKB = (file.size / 1024).toFixed(2);
      setFileInfo({ name: file.name, size: sizeInKB });
      setError("");
      
      const jsonData = data.map((row) => ({
        firstName:
          row["firstname"] ||
          row["firstName"] ||
          row["FirstName"] ||
          row["first_name"] ||
          row["first-name"] ||
          row["first name"] ||
          row["First Name"] ||
          row["fname"] ||
          row["first"] ||
          "",
        lastName:
          row["lastname"] ||
          row["lastName"] ||
          row["LastName"] ||
          row["last_name"] ||
          row["last-name"] ||
          row["last name"] ||
          row["Last Name"] ||
          row["lname"] ||
          row["last"] ||
          "",
        phoneNumber: (
          row["phonenumber"] ||
          row["phoneNumber"] ||
          row["PhoneNumber"] ||
          row["phone_number"] ||
          row["phone-number"] ||
          row["phone number"] ||
          row["Phone Number"] ||
          row["phone"] ||
          row["mobile"] ||
          row["mobile_number"] ||
          row["mobile-number"] ||
          row["mobile number"] ||
          row["Mobile Number"] ||
          row["contact"] ||
          row["contact_number"] ||
          row["contact-number"] ||
          row["contact number"] ||
          row["Contact Number"] ||
          row["tel"] ||
          row["telephone"] ||
          ""
        )
          .toString()
          .replace(/\s+|[()]/g, ""), // Remove spaces and brackets
      }));
      
      // Filter out rows with empty values or invalid phone numbers
      const validJsonData = jsonData
        .filter(
          (row) =>
            row.firstName &&
            row.lastName &&
            // Check if phone number matches any valid format:
            // 10 digits, or starts with 0, 44, or +44 followed by 10 digits
            (/^(\d{10}|0\d{10}|44\d{10}|\+44\d{10})$/.test(row.phoneNumber) ||
              // Keep the special case for developer testing
              (row.phoneNumber.startsWith("+91") &&
                row.phoneNumber.length === 13))
        )
        .map((row) => {
          let formattedPhone = row.phoneNumber;

          // Handle UK number formatting
          if (!formattedPhone.startsWith("+91")) {
            // Remove leading 0, 44, or +44 if present
            formattedPhone = formattedPhone.replace(/^(0|44|\+44)/, "");
            // Add +44 prefix to the 10-digit number
            formattedPhone = `+44${formattedPhone}`;
          }

          // // Handle UK number formatting
          // if (
          //   !row.phoneNumber.startsWith("+91") &&
          //   (formattedPhone.length === 11 || formattedPhone.length === 10)
          // ) {
          //   formattedPhone = `+44${
          //     formattedPhone.startsWith("0")
          //       ? formattedPhone.slice(1)
          //       : formattedPhone
          //   }`; // Add +44 prefix
          // }

          return {
            ...row,
            phoneNumber: formattedPhone,
          };
        });
      
      console.log("validJsonData", validJsonData);

      if (validJsonData.length === 0) {
        setError(
          "Some rows have invalid fields or possibly invalid phone numbers. Please check your CSV file."
        );
        setFileInfo({ name: "", size: 0 });
      } else {
        setUploadedCandidates(validJsonData);
        setError("");
      }
    }
  };

  const handleFileRemove = () => {
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      fileInput.value = "";
    }
    setFileInfo({ name: "", size: 0 });
    setError(""); // Clear error on file removal
  };

  return (
    <Fragment>
      <label
        htmlFor="file-upload"
        className="border border-dashed border-[#35353599] bg-[#F9F9F9] rounded-lg min-h-[220px] flex flex-col justify-center items-center cursor-pointer"
      >
        <input
          id="file-upload"
          type="file"
          accept=".csv,.xlsx,.xls"
          className="hidden"
          onChange={handleFileChange}
        />
        {fileInfo.name ? (
          <div className="flex justify-between items-center bg-[#2764D214] py-[14px] px-3 w-full max-w-[calc(100%_-_48px)] rounded-lg">
            <div className="flex items-center w-full gap-[6px]">
              <img
                src={FileUploadIcon}
                alt="FileUploadIcon"
                className="w-5 h-5"
              />
              <span className="text-sm text-gray-600">
                Selected file: {fileInfo.name} ({fileInfo.size} KB)
              </span>
            </div>
            <div
              className="w-6 h-6 flex justify-center items-center"
              onClick={(e) => {
                e.stopPropagation(); // Prevent the click event from triggering the file input
                e.preventDefault();
                handleFileRemove();
              }}
            >
              <img src={CrossIcon} alt="CancelIcon" className="w-full h-full" />
            </div>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <img src={UploadIcon} alt="UploadIcon" width={20} height={20} />
            <span className="block text-sm font-medium text-[#121212] mt-2">
              Drag & Drop your file here or Browse file
            </span>
            <p className="text-xs text-[#353535]">
              (File must be in CSV or Excel format)
            </p>
          </div>
        )}
      </label>
      {error && (
        <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
          {error}
        </span>
      )}
    </Fragment>
  );
}
