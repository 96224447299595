import React, { useState } from "react";
import { Paths } from "routes";
import {
  ButtonBgGradient,
  HoverBg,
  TypeSelectionImg1,
  TypeSelectionImg2,
} from "assets";
import { useNavigate } from "react-router-dom";

export default function SelectionType({ auth }) {
  const navigate = useNavigate();
  // const [btnToggle,setBtnToggle] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(null); // Track hovered card ID

  const typeSelection = [
    {
      id: 1,
      typeImg: TypeSelectionImg1,
      typeTitle: "Find a perfect candidate for my job",
      path: Paths.JobOverview,
    },
    {
      id: 2,
      typeImg: TypeSelectionImg2,
      typeTitle: "Connect my candidate with a perfect company",
      path: Paths.CompanyOverview,
    },
  ];
  return (
    <div className="py-4 sm:py-6 lg:py-10 px-4 sm:px-6 lg:px-12 h-full max-h-[calc(100vh_-_75px)] flex flex-col flex-1 bg-[#FDFEFF] ">
      <div className="w-full h-full flex justify-center items-start py-[52px] bg-[#FDFEFF] border border-[#E5E5E5] rounded-xl overflow-auto">
        <div className="xl:w-[calc(100%_-_282px)] md:w-[calc(100%_-_122px)] w-full md:px-0 px-7 flex flex-col gap-9">
          <div className="flex flex-col justify-center items-center gap-1">
            <h2 className="text-[28px] font-semibold leading-9 text-[#353535]">
              Hello {auth?.userData?.FirstName}
            </h2>
            <span className="text-[#121212] md:text-lg text-base leading-6 -tracking-[0.18px] text-center">
              What can our AI do for you?
            </span>
          </div>
          <div className="flex lg:flex-row flex-col gap-7">
            {typeSelection.map((typeItem) => {
              return (
                <div
                  className="w-full flex flex-col items-center gap-[15px] cursor-pointer"
                  onClick={() => navigate(typeItem.path)}
                >
                  <div
                    key={typeItem.id}
                    onMouseOver={() => setHoveredCard(typeItem.id)}
                    onMouseLeave={() => setHoveredCard(null)}
                    className={`w-full h-full min-h-[300px] max-h-[300px] pt-6 pb-[30px] xl:px-[62px] md:px-[42px] px-6 flex flex-col justify-start items-center rounded-xl border border-[#E5E5E5] bg-[#FDFEFF] relative group ${
                      hoveredCard === typeItem.id
                        ? "hover:shadow-[0px_0px_40px_rgba(0,0,0,0.07)]"
                        : ""
                    }`}
                  >
                    <div className="w-[232px] h-[232px] flex justify-center items-center">
                      <img src={typeItem.typeImg} alt="typeSelectionImg" />
                    </div>
                    <h5
                      className={`w-full absolute bottom-[30px] left-1/2 -translate-x-1/2 xl:text-xl text-lg font-medium xl:leading-[25px] leading-[22px] -tracking-[0.2px] text-[#061025] text-center ${
                        typeItem.id === 1 ? " max-w-[201px]" : "max-w-[235px]"
                      }`}
                    >
                      {typeItem.typeTitle}
                    </h5>
                  </div>
                  {hoveredCard === typeItem.id && (
                    <button
                      type="button"
                      className="py-2 px-4 flex justify-center items-center rounded-full bg-[#061025] text-[13px] font-medium leading-[18px] text-white capitalize"
                      style={{
                        backgroundImage: `url(${ButtonBgGradient})`,
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      {typeItem.id === 1 ? "call canditate" : "call companies"}
                    </button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
