import { forwardRef, useState } from "react";
import { Dialog, DialogContent, Slide } from "@mui/material";
import {
  ArrowDown,
  CrossIconWhite,
  GradientHeaderBg,
  HorizontalDots,
  PlusIcon,
} from "assets";
import SalaryRangeSlider from "component/SalaryRangeSlider";
import FileUploadV2 from "component/FileUploadV2";
import CandidateCvUpload from "component/CandidateCvUpload";
// import Stepper from "component/Stepper";
import Stepper from "component/StepperV2";
import {
  handleDownloadSampleCompanyCsv,
  handleDownloadSampleCsv,
} from "utils/common";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const NewCompanyCandidate = (props) => {
  const {
    open,
    setOpen,
    handleCreateCandidate,
    mode = "add",
    candidateData,
    editState,
  } = props;

  const jobCreationSteps = [
    "Candidate's Details",
    "Upload Companies",
    "Call Prompts",
  ];

  const [step, setStep] = useState(editState === "edit_call" ? 2 : 0);
  const [companyCandidateDetail, setCompanyCandidateDetail] = useState({
    candidateName: candidateData?.name || "",
    candidatePosition: candidateData?.position || "",
    prompt: candidateData?.prompt || "",
  });
  const [cvFileInfo, setCvFileInfo] = useState({
    name: candidateData?.cvPath || "",
    size: 0,
    base64: "",
    file: null,
  });
  const [fileInfo, setFileInfo] = useState({ name: "", size: 0 });
  const [uploadedCompanies, setUploadedCompanies] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [manualAccordian, setManualAccordian] = useState(false);
  const [manualUploadValues, setManualUploadValues] = useState([
    { name: "", phoneNumber: "+44", location: "" },
  ]);
  const [errors, setErrors] = useState([
    // manual upload fields value error
    { name: "", phoneNumber: "", location: "" },
  ]);
  // New state to handle validation errors
  const [validationErrors, setValidationErrors] = useState({
    candidateName: "",
    candidatePosition: "",
    manualDetail: "",
    fileInfo: "",
    cvFileInfo: "",
    prompt: "",
  });

  const errorMsg = {
    candidateName: "Candidate name is required",
    candidatePosition: "Candidate position is required",
    manualDetail: "Please fill up required detail",
    fileInfo: "CSV or Excel file must be uploaded",
    cvFileInfo: "PDF or DOCX file must be uploaded",
    prompt: "Promt detail is required",
    name: "Company name is required",
    location: "Location is required",
  };

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const handleChangePostDetails = (field, value) => {
    setCompanyCandidateDetail((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (field !== "prompt") {
      setValidationErrors((prev) => ({
        ...prev,
        [field]: value ? "" : errorMsg[field],
      }));
    }
  };

  const validateManualUploadField = (name, value) => {
    let errMsg = "";
    if (!value.trim()) {
      errMsg = errorMsg[name];
    } else if (name === "phoneNumber") {
      const formattedValue = value.replace(/\D/g, "");
      if (!formattedValue.startsWith("44") || formattedValue.length !== 12) {
        errMsg = "Enter a valid UK number";
      }
    }
    return errMsg;
  };

  const handleManualUploadFieldChange = (index, name, value) => {
    const updatedFields = [...manualUploadValues];
    updatedFields[index][name] =
      name === "phoneNumber"
        ? value.startsWith("+44")
          ? "+44" + value.slice(3).replace(/\D/g, "")
          : "+44"
        : value;

    setManualUploadValues(updatedFields);

    // Dynamically validate the field
    const updatedErrors = [...errors];
    updatedErrors[index][name] = validateManualUploadField(
      name,
      updatedFields[index][name]
    );
    setErrors(updatedErrors);
  };

  const handeManualUploadFieldBlur = (index, name) => {
    const updatedErrors = [...errors];
    updatedErrors[index][name] = validateManualUploadField(
      name,
      manualUploadValues[index][name]
    );
    setErrors(updatedErrors);
  };

  const validateManualUploadAllFields = () => {
    const nonEmptyValues = manualUploadValues.filter(
      (field) =>
        field.name.trim() ||
        field.location.trim() ||
        field.phoneNumber.trim() !== "+44"
    );
    // If no valid entries remain, keep at least one empty form
    if (nonEmptyValues.length === 0) {
      setManualUploadValues([{ name: "", phoneNumber: "+44", location: "" }]);
      const newErrors = [{ name: "", phoneNumber: "+44", location: "" }].map(
        (field) => ({
          name: validateManualUploadField("name", field.name),
          location: validateManualUploadField("name", field.location),
          phoneNumber: validateManualUploadField(
            "phoneNumber",
            field.phoneNumber
          ),
        })
      );
      setErrors(newErrors);
      return false;
    }
    setManualUploadValues(nonEmptyValues);
    const newErrors = nonEmptyValues.map((field) => ({
      name: validateManualUploadField("name", field.name),
      phoneNumber: validateManualUploadField("phoneNumber", field.phoneNumber),
      location: validateManualUploadField("location", field.location),
    }));

    setErrors(newErrors);

    // Check if all manualUploadValues are valid
    return newErrors.every(
      (error) => !error.name && !error.phoneNumber && !error.location
    );
  };

  const handleAddManualUploadFields = () => {
    // clear if anything uploaded through file
    setUploadedCompanies([]);
    setFileInfo({ name: "", size: 0 });
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      fileInfo: "", // Clear file error
    }));

    if (validateManualUploadAllFields()) {
      setManualUploadValues([
        ...manualUploadValues,
        { name: "", phoneNumber: "+44", location: "" },
      ]);
      setErrors([...errors, { name: "", phoneNumber: "", location: "" }]);
    }
    const element = document.getElementById("add-field-button");
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };
  console.log("errors", errors);

  const renderManualUploadInputField = (
    index,
    name,
    placeholder,
    label,
    type = "text"
  ) => (
    <div
      className={`flex flex-col gap-1 ${
        name === "phoneNumber" ? "w-[150px]" : "flex-1 w-full"
      }`}
    >
      <label className="text-[#1F1F1F] text-[13px] font-normal leading-5 -tracking-[0.13px] ">
        {label}
      </label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        value={manualUploadValues[index][name]}
        onChange={(e) =>
          handleManualUploadFieldChange(index, name, e.target.value)
        }
        onBlur={() => handeManualUploadFieldBlur(index, name)}
        className={`w-full py-[14px] pl-4 pr-[10px] border rounded-lg text-[15px] text-[#393939] font-normal leading-5 focus-visible:outline-none ${
          errors[index]?.[name] ? "border-red-500" : "border-[#E5E5E5]"
        }`}
      />
      {errors[index]?.[name] && (
        <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
          {errors[index][name]}
        </div>
      )}
    </div>
  );

  const validateStep1 = () => {
    const errors = {};
    if (!companyCandidateDetail.candidateName) {
      errors.candidateName = errorMsg.candidateName;
    }
    if (!companyCandidateDetail.candidatePosition) {
      errors.candidatePosition = errorMsg.candidatePosition;
    }
    if (mode === "add" && !cvFileInfo.name) {
      errors.cvFileInfo = errorMsg.cvFileInfo;
    }
    return errors;
  };

  const validateStep2 = () => {
    console.log("fileInfo", fileInfo);
    const errors = {};
    if (fileInfo.name) {
      return errors;
    }

    if (!fileInfo.name && manualAccordian) {
      if (!validateManualUploadAllFields()) {
        errors.manualDetail = errorMsg.manualDetail;
      }
    } else if (!fileInfo.name) {
      errors.fileInfo = errorMsg.fileInfo;
    }

    return errors;
  };

  const handleNextStep = () => {
    let errors = {};
    if (step === 0) {
      errors = validateStep1();
    } else if (step === 1) {
      errors = validateStep2();
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
    } else {
      setValidationErrors({});
      setStep(step + 1); // Move to the next step if no errors
    }
  };

  const renderStep1Content = () => {
    return (
      <>
        {mode === "add" && (
          <div className="flex flex-col gap-3">
            <h4 className="text-[#1F1F1F] text-base font-medium leading-[22px] -tracking-[0.16px]">
              Upload Candidate CV
            </h4>
            <CandidateCvUpload
              fileInfo={cvFileInfo}
              setFileInfo={(file) => {
                setCvFileInfo(file);
                // Clear file validation error if file is uploaded
                if (file && file.name) {
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    cvFileInfo: "", // Clear file error
                  }));
                }
              }}
            />
            {validationErrors.cvFileInfo && (
              <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                {validationErrors.cvFileInfo}
              </span>
            )}
          </div>
        )}
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-[6px]">
            <label className="text-[#1F1F1F] text-[15px] font-normal leading-5">
              Candidate's Full Name
            </label>
            <input
              type="text"
              placeholder="Ex John Doe"
              className="py-[14px] pl-4 pr-[10px] border border-[#E5E5E5] rounded-lg text-[15px] text-[#393939] font-normal leading-5 focus-visible:outline-none"
              value={companyCandidateDetail.candidateName || ""}
              onChange={(event) => {
                handleChangePostDetails("candidateName", event.target.value);
              }}
            />
            {validationErrors.candidateName && (
              <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                {validationErrors.candidateName}
              </span>
            )}
          </div>
          <div className="flex flex-col gap-[6px]">
            <label className="text-[#1F1F1F] text-[15px] font-normal leading-5">
              Position or Role(s) he's interested in
            </label>
            <input
              type="text"
              placeholder="Ex Project Manager"
              className="py-[14px] pl-4 pr-[10px] border border-[#E5E5E5] rounded-lg text-[15px] text-[#393939] font-normal leading-5 focus-visible:outline-none"
              value={companyCandidateDetail.candidatePosition || ""}
              onChange={(event) => {
                handleChangePostDetails(
                  "candidatePosition",
                  event.target.value
                );
              }}
            />
            {validationErrors.candidatePosition && (
              <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                {validationErrors.candidatePosition}
              </span>
            )}
          </div>
        </div>
      </>
    );
  };

  const handleAccordionClick = () => {
    setManualAccordian(!manualAccordian);
    // Add smooth scroll after state update
    if (!manualAccordian) {
      setTimeout(() => {
        const scrollToElement = document.getElementById("add-field-button");
        if (scrollToElement) {
          scrollToElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  };

  const renderStep2Content = () => {
    return (
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <h2 className="text-[##1F1F1F] text-base leading-[22px] font-medium -tracking-[0.16px]">
            Upload Companies as a .CSV file
          </h2>
          <h3 className="text-[#1F1F1F] text-[13px] leading-5 font-light tracking-[-0.13px]">
            Please upload a CSV file with the candidates names and contact
            numbers.{" "}
            <span
              className="text-blue-500 font-semibold underline cursor-pointer"
              onClick={handleDownloadSampleCompanyCsv}
            >
              Click here
            </span>{" "}
            to see a template of what it should look like.
          </h3>
        </div>
        <div className="flex flex-col gap-3">
          <>
            <FileUploadV2
              fileInfo={fileInfo}
              setFileInfo={(file) => {
                setFileInfo(file);
                // Clear file validation error if file is uploaded
                if (file && file.name) {
                  setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    fileInfo: "", // Clear file error
                  }));
                  setManualUploadValues([
                    { name: "", phoneNumber: "+44", location: "" },
                  ]);
                  setErrors([{ name: "", phoneNumber: "", location: "" }]);
                  setManualAccordian(false);
                }
              }}
              setUploadedDetails={setUploadedCompanies}
            />
            {validationErrors.fileInfo && (
              <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
                {validationErrors.fileInfo}
              </span>
            )}
          </>
        </div>
        <div className={`mt-1`}>
          <div className="flex justify-center items-center text-[#1f1f1f66] relative before:content-[''] before:absolute before:top-1/2 before:left-0 before:h-[1px] before:w-[calc(50%_-_20px)] before:bg-[#E5E5E5] after:content-[''] after:absolute after:top-1/2 after:right-0 after:h-[1px] after:w-[calc(50%_-_20px)] after:bg-[#E5E5E5]">
            OR
          </div>
          <div className={`border-b-[1px] border-[#EFEFEF] pt-3 pb-[19px]`}>
            <div
              className="cursor-pointer flex justify-between items-center text-[16px] font-medium text-[#1F1F1F] leading-[22px] tracking-[0.16px]"
              onClick={handleAccordionClick}
            >
              Upload Manually
              {manualAccordian ? (
                <div className="md:w-6 w-5 md:h-6 h-5 rounded-full bg-[#F9F9F9] flex justify-center items-center">
                  <img src={ArrowDown} alt="ArrowUp" className="rotate-180" />
                </div>
              ) : (
                <div className="md:w-6 w-5 md:h-6 h-5 rounded-full bg-[#F9F9F9] flex justify-center items-center">
                  <img src={ArrowDown} alt="ArrowDown" />
                </div>
              )}
            </div>

            <div
              className={`mt-[17px] transition-all duration-700 ease-in-out overflow-hidden ${
                manualAccordian
                  ? "max-h-full block opacity-100"
                  : "max-h-0 hidden opacity-0"
              }`}
            >
              <div className="flex flex-col gap-4">
                {manualUploadValues.map((field, index) => (
                  <div className="flex w-full">
                    <div key={index} className="flex w-full items-center gap-4">
                      <div className="flex w-full items-start gap-4">
                        {renderManualUploadInputField(
                          index,
                          "name",
                          "Ex GreenCode",
                          "Company Name"
                        )}
                        {renderManualUploadInputField(
                          index,
                          "location",
                          "Baker St, London, UK",
                          "Location"
                        )}
                        {renderManualUploadInputField(
                          index,
                          "phoneNumber",
                          "Ex +44 24 3421 331",
                          "Phone Number"
                        )}
                      </div>
                      {manualUploadValues.length - 1 === index ? (
                        <button
                          type="button"
                          className={`min-w-8 min-h-8 mt-[29px] bg-custom-primary-dark rounded-full flex justify-center items-center ${
                            errors[index]?.name || errors[index]?.phoneNumber
                              ? "mb-6"
                              : ""
                          }`}
                          onClick={handleAddManualUploadFields}
                        >
                          <img src={PlusIcon} alt="addIcon" />
                        </button>
                      ) : (
                        <button
                          type="button"
                          id="add-field-button"
                          className={`opacity-0 min-w-8 min-h-8 mt-[29px] bg-[#F9F9F9] rounded-full flex justify-center items-center ${
                            errors[index]?.name || errors[index]?.phoneNumber
                              ? "mb-6"
                              : ""
                          }`}
                        >
                          <img
                            src={HorizontalDots}
                            alt="horizontalThreeDlotsIcon"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="flex justify-end mt-3">
                <button
                  type="button"
                  id="add-field-button"
                  onClick={handleAddManualUploadFields}
                  className="h-[36px] w-[72px] flex justify-center items-center bg-custom-primary text-white rounded-lg py-[10px] px-6"
                >
                  Add
                </button>
              </div> */}
            </div>
          </div>
          {validationErrors.manualDetail && (
            <span className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
              {validationErrors.fileInfo}
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderStep3Content = () => {
    return (
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <h2 className="text-base text-[#1F1F1F] leading-[22px] -tracking-[1%] font-medium">
            Train our AI agent
          </h2>
          <h3 className="text-[#1F1F1F] text-[13px] leading-5 -tracking-[1%] font-light">
            Use this space for additional context for your AI agent. It will
            help them tailor the conversation with the details you want. You can
            write multiple prompts.
          </h3>
        </div>
        <div className="flex flex-col gap-[6px]">
          <label className="text-[#1F1F1F] text-[13px] font-normal leading-5 -tracking-[0.13px]">
            Prompt Details for a Call
          </label>
          <textarea
            name="Prompt Details for a Call"
            rows="7"
            placeholder={`Ex "Make sure to ask about the budget"`}
            value={companyCandidateDetail.prompt || ""}
            onChange={(event) => {
              handleChangePostDetails("prompt", event.target.value);
            }}
            className="input text-gray-700 bg-white border border-gray-300 rounded-lg py-4 px-4 block w-full outline-none resize-none"
          />
          {validationErrors.prompt && (
            <div className="text-[13px] leading-5 -tracking-[0.13px] font-normal text-[#FF352A]">
              {validationErrors.prompt}
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleSubmit = () => {
    setValidationErrors({});
    handleCreateCandidate({
      name: companyCandidateDetail.candidateName,
      position: companyCandidateDetail.candidatePosition,
      prompt: companyCandidateDetail.prompt,
      // cvData: cvFileInfo.base64,
      cvFile: cvFileInfo.file,
      // cvPath: cvFileInfo.name,
      companies:
        fileInfo?.name && uploadedCompanies.length > 0
          ? uploadedCompanies
          : manualUploadValues,
    });
  };

  const handleEditSubmit = async () => {
    let errors = {};
    if (editState === "edit_candidate_details") {
      errors = validateStep1();
    }
    // else if (editState === "edit_call") {
    //   errors = validateStep3()
    // }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setSubmitLoading(true);
    setValidationErrors({});
    await handleCreateCandidate({
      name: companyCandidateDetail.candidateName,
      position: companyCandidateDetail.candidatePosition,
      prompt: companyCandidateDetail.prompt,
      cvPath: cvFileInfo.name,
    });
    setSubmitLoading(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "820px",
            borderRadius: "12px",
          },
        }}
      >
        <DialogContent
          className="!p-0 relative font-hankengrotesk"
          style={{
            overflow: "hidden",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${GradientHeaderBg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            className="w-full h-full min-h-[44px] flex justify-between items-center bg-[#061025] bg-gradient-header pl-7 pr-4"
          >
            <div
              // className={`text-lg font-medium leading-[22px] -tracking-[0.18px] text-white
              // ${mode === "edit" ? "mb-6" : ""}
              // `}
              className={`text-lg font-medium leading-[22px] -tracking-[0.18px] text-white`}
            >
              {mode === "add" ? "New Company" : "Update Candidate"}
            </div>
            <div
              // className="absolute top-4 right-4 cursor-pointer"
              className="w-6 h-6 cursor-pointer"
              onClick={handleClose}
            >
              <img src={CrossIconWhite} alt="crossIcon" />
            </div>
          </div>
          <div className="w-full h-full max-h-[calc(100vh_-_159px)] min-h-[calc(100vh_-_159px)] flex flex-col flex-1 overflow-auto">
            {mode === "add" && (
              <div className={`w-full max-w-[600px] rounded-md mx-auto my-9`}>
                <Stepper selectedStep={step} steps={jobCreationSteps} />
              </div>
            )}
            <div
              className={`${
                mode === "edit" ? "mt-9" : ""
              } w-full h-full flex-1 flex flex-col scrollbar overflow-auto`}
            >
              <div
                className={`w-full max-w-[600px] h-full min-h-[438px] max-h-[438px] mx-auto flex flex-col gap-6`}
              >
                {step === 0
                  ? renderStep1Content()
                  : step === 1
                  ? renderStep2Content()
                  : step === 2
                  ? renderStep3Content()
                  : null}
              </div>
            </div>

            <div
              className={`w-full max-w-[600px] pt-[22px] pb-[52px] mx-auto flex justify-end items-center gap-3 sm:gap-0`}
            >
              <button
                className={`btn h-[50px] bg-white flex items-center border-[#061025] border-[1px] text-black border-purple font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg  ${
                  step === 0 ? "opacity-40 cursor-not-allowed" : ""
                }`}
                onClick={() => {
                  if (step > 0) {
                    setStep(step - 1);
                    setValidationErrors({});
                  }
                }}
                disabled={step === 0}
              >
                {mode === "edit" ? "Cancel" : "Back"}
              </button>
              <button
                className="btn h-[50px] flex items-center bg-[#061025] text-white font-semibold lg:py-4 py-3 lg:px-12 px-7 rounded-lg  sm:ml-4 disabled:opacity-75"
                onClick={() =>
                  mode === "edit"
                    ? handleEditSubmit()
                    : step === 2
                    ? handleSubmit()
                    : handleNextStep()
                }
                disabled={submitLoading}
              >
                {submitLoading
                  ? "Submitting..."
                  : step === 2 || mode === "edit"
                  ? "Submit"
                  : "Next"}
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewCompanyCandidate;
