import { Table, TableHead, TableRow } from "@mui/material";
import { NoSearchResult } from "assets";
import { StyledTableCell } from "component/Table";
import React, { Fragment } from "react";

export default function CandidateEmptyScreen({ columns, handleRefresh, module }) {
  return (
    <Fragment>
      <Table
        sx={{
          minWidth: 700,
        }}
        aria-label="customized table"
        stickyHeader
        className="sticky top-0"
      >
        <TableHead className="bg-[#2764d20d] rounded sticky top-0 z-10">
          <TableRow>
            {/* {isHeaderCheckbox && <StyledTableCell align="center"  >
                  <img src={EditIcon} className="min-w-5 min-h-5 mx-auto" alt="edit-icon" />
                </StyledTableCell>} */}
            {columns.map((col, index) => (
              <StyledTableCell key={index} className="whitespace-nowrap">
                {col.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
      <div className="w-full bg-white mx-auto flex flex-col justify-center items-center gap-6 p-4">
        <div className="w-full max-w-[525px] mx-auto flex flex-col justify-center items-center gap-[6px] text-center">
          <img
            src={NoSearchResult}
            alt="NoSearchResult"
            className="max-w-full h-auto"
          />
          <p className="m-0 text-center text-xl sm:text-2xl leading-7 sm:leading-8 text-[#2E2E2E] font-medium">
            We couldn't find any results based on your search, please try again.
          </p>
        </div>
        <button
          className={`${module === "job" ? "bg-custom-primary" : "bg-custom-primary-dark"} text-white px-4 sm:px-6 py-2 rounded-md text-sm sm:text-base`}
          onClick={() => handleRefresh()}
        >
          Refresh
        </button>
      </div>
    </Fragment>
  );
}
