import { PhoneCompletedIcon, PhoneMissedIcon, UserIconPrimary } from "assets";
import React from "react";
import { ArrowLeft } from "assets";
import { useNavigate } from "react-router-dom";

export default function CandidateList({ candidate, isFromCandidateDetail }) {
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-col sm:flex-row justify-between border border-[#E5E5E5] rounded-lg cursor-pointer ${
        isFromCandidateDetail ? "p-0 border-none cursor-auto mb-[10px]" : "py-3"
      } `}
      onClick={() =>
        !isFromCandidateDetail && navigate(`/candidate/${candidate?.id}`)
      }
    >
      <div
        className={`flex items-center gap-3 flex-1 ${
          isFromCandidateDetail ? "pl-0 py-0" : "pl-5 py-1"
        }`}
      >
        <div
          className={`${
            isFromCandidateDetail
              ? "min-w-[52px] min-h-[52px]"
              : "min-w-10 min-h-10"
          } flex justify-center items-center bg-[#F6F9FD] rounded-full`}
        >
          <img
            src={UserIconPrimary}
            alt="userIcon"
            width={isFromCandidateDetail ? 26 : 20}
            height={isFromCandidateDetail ? 26 : 20}
          />
        </div>
        <div
          className={`flex ${isFromCandidateDetail ? "flex-row items-center" : "flex-col items-start"}`}
        >
          <h3
            className={`!-tracking-[-0.18px] ${
              isFromCandidateDetail
                ? "text-[#353535] text-[28px] font-semibold !leading-[36px]"
                : "text-[#121212] text-lg font-medium !leading-[22px]"
            }`}
          >
            {candidate?.name}
          </h3>
          {isFromCandidateDetail && <span className="mx-1 font-semibold text-[28px]">/</span>}
          <span
            className={` font-light ${
              isFromCandidateDetail
                ? "text-[28px] text-[#353535] leading-[36px]"
                : "text-xs text-[#121212] leading-[18px]"
            }`}
          >
            {candidate?.position}
          </span>
        </div>
      </div>

      <div className="py-1 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-6">
        <div className="flex gap-2">
          <div className="flex items-center gap-2 bg-green-100 text-green-800 px-3 py-1 rounded-full">
            <img
              src={PhoneCompletedIcon}
              alt="PhoneCompletedIcon"
              className="w-4 h-4"
            />
            <span className="text-xs sm:text-[13px] font-medium leading-5 text-[#121212]">
              {candidate?.successfulCallsCount || 0}
            </span>
          </div>
          <div className="flex items-center gap-2 bg-red-100 text-red-800 px-3 py-1 rounded-full">
            <img
              src={PhoneMissedIcon}
              alt="PhoneMissedIcon"
              className="w-4 h-4"
            />
            <span className="text-xs sm:text-[13px] font-medium leading-5 text-[#121212]">
              {candidate?.failedCallsCount || 0}
            </span>
          </div>
        </div>
        <div className="sm:border-l border-[#E5E5E5] sm:pl-4 md:pl-6 flex flex-col justify-center items-start gap-[2px]">
          <h6 className="text-[#727272] text-xs font-light leading-[18px]">
            Potential Companies
          </h6>
          <span className="text-[#121212] text-sm font-semibold leading-5">
            {candidate?.candidateCount || 0} Companies
          </span>
        </div>
      </div>
      {!isFromCandidateDetail && (
        <div className="w-12 h-12 border border-[#E5E5E5] rounded flex justify-center items-center ml-10 mr-3">
          <img src={ArrowLeft} alt="right-arrow" className="w-4 h-4" />
        </div>
      )}
    </div>
  );
}
