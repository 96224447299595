import {
  UserIconPrimary,
  ArrowLeft,
  MapPinIcon,
} from "assets";
import React from "react";

export default function CompanyList({ company }) {
  return (
    <div
      className={`flex flex-col sm:flex-row justify-between border border-[#E5E5E5] rounded-lg cursor-pointer p-4`}
    >
      <div className="flex gap-3">
        <div className="flex items-center bg-[#F6F9FD] p-[10px] rounded-full">
          <img src={UserIconPrimary} alt="userIcon" width={20} height={20} />
        </div>
        <div>
          <h3
            className={`text-lg sm:text-xl !leading-[22px] !-tracking-[-0.2px] font-medium text-[#121212]`}
          >
            {company?.name}
          </h3>
          <span className="text-[#121212] text-xs sm:text-[13px] font-light leading-[21px]">
            {company?.position}
          </span>
        </div>
      </div>

      <div className="flex flex-row justify-between items-start sm:items-center gap-4 sm:gap-6">
        <div className="flex items-center gap-1">
          <img src={MapPinIcon} alt="MapPinIcon" className="w-4 h-4" />
          <span className="text-[13px] font-normal leading-5 text-[#353535]">
            {company?.location}
          </span>
        </div>
        <div className="sm:border-l border-[#E5E5E5] sm:pl-4 md:pl-6 flex flex-row justify-center items-center gap-1">
          <img src={MapPinIcon} alt="MapPinIcon" className="w-4 h-4" />
          <span className="text-[13px] font-normal leading-5 text-[#353535]">
            {company?.phoneNumber}
          </span>
        </div>
        <div className="border border-[#E5E5E5] rounded p-2 sm:p-3 flex justify-center items-center">
          <img src={ArrowLeft} alt="right-arrow" className="w-4 h-4" />
        </div>
      </div>
    </div>
  );
}
