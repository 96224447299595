import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Loader from "component/Loader";

export default function CandidateListMenuPopper({
  anchorRef,
  handleClose,
  candidateList,
  handleCandidateClick
}) {
  return (
    <Menu
      anchorEl={anchorRef}
      id="account-menu"
      open={Boolean(anchorRef)}
      onClose={handleClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      sx={{
        "& .MuiPaper-root": {
          width: "100%",
          height: "100%",
          maxWidth: "378px",
          maxHeight: "258px",
          minHeight: "258px",
          overflow: "auto",
          boxShadow: "2px 2px 15px 0px rgba(0, 0, 0, 0.05)",
          borderRadius: "12px",
          "& .MuiList-root": {
            padding: "0px",
            "& .MuiButtonBase-root": {
              padding: "12px 12px 12px 16px",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              margin: "0px",
              borderBottom: "1px solid #E9E9E9",
              "&:last-child": {
                borderBottom: "none",
              },
            },
          },
        },
      }}
      classes={{
        paper: "scrollbar",
      }}
    >
      {!candidateList ? (
        <Loader />
      ) : candidateList && candidateList?.length > 0 ? (
        candidateList.map((candidate, index) => (
          <MenuItem key={index} onClick={() => handleCandidateClick(candidate.id)}>
            <div className="flex justify-between items-center w-full font-hankengrotesk">
              <span className="w-full max-w-[225px] text-[#121212] text-[14px] font-medium leading-[19px] text-ellipsis overflow-hidden whitespace-nowrap">
                {candidate?.name}
              </span>
              <div className="flex gap-1">
                <p className="text-[#12121280] font-normal text-[14px] leading-[19px]">
                  candidate ID:
                </p>
                <p className="text-[#121212] text-[14px] font-normal leading-[19px]">
                  {candidate.id}
                </p>
              </div>
            </div>
          </MenuItem>
        ))
      ) : (
        `No Candidate Found`
      )}
    </Menu>
  );
}
