import Skeleton from "@mui/material/Skeleton";
import { StyledTableCell, StyledTableRow } from "component/Table";
import React from "react";

export default function CompanyListDetailSkelaton() {
  return (
    <StyledTableRow>
      <StyledTableCell component="td" scope="row" className="p-2 min-w-[150px]">
        <Skeleton
          variant="rectangular"
          width={"80%"}
          height={28}
          className="bg-[#F2F2F2] rounded mx-auto"
        />
      </StyledTableCell>
      <StyledTableCell className="p-2" scope="row">
        <Skeleton
          variant="rectangular"
          width={"60%"}
          height={28}
          className="bg-[#F2F2F2] rounded mx-auto"
        />
      </StyledTableCell>
      <StyledTableCell className="p-2" scope="row">
        <Skeleton
          variant="rectangular"
          width={"60%"}
          height={28}
          className="bg-[#F2F2F2] rounded mx-auto"
        />
      </StyledTableCell>
      <StyledTableCell className="p-2">
        <Skeleton
          variant="rectangular"
          width={"50%"}
          height={28}
          className="bg-[#F2F2F2] rounded mx-auto"
        />
      </StyledTableCell>
      <StyledTableCell className="p-2 min-w-[210px]">
        <Skeleton
          variant="rectangular"
          width={"80%"}
          height={28}
          className="bg-[#F2F2F2] rounded mx-auto"
        />
      </StyledTableCell>
    </StyledTableRow>
  );
}
