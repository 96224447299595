import { forwardRef, useEffect, useState } from "react";
import { useNotification } from "contexts/notificationContext";
import { Dialog, DialogContent, Slide } from "@mui/material";
import {
  CrossIcon,
  SearchBlue,
  AudioVoiceIcon,
  EmptyNoteSummary,
} from "assets";
import ReactAudioPlayer from "react-audio-player";
import Loader from "component/Loader";
import axios from "axios";
import ContactCandidate from "./ContactCandidate";
import { BubbleCircle } from "assets";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TranscriptionDetail = (props) => {
  const { open, setOpen, selectedCandidate, isShowDetailOnly } = props;
  const { showNotification } = useNotification();
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [isContactCandidate, setIsContactCandidate] = useState(false);
  const [transcript, setTranscript] = useState([]);

  const fetchData = async () => {
    const url = `https://ai-call-public-data.s3.eu-west-1.amazonaws.com/call-transcripts/${selectedCandidate?.callSid}.json`;

    try {
      if (selectedCandidate && selectedCandidate.callSid) {
        setLoading(true);
        const response = await axios.get(url);
        const data = response.data;
        setTranscript(data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const callApi = async () => {
      await fetchData();
    };
    if (open || isShowDetailOnly) callApi();
  }, [open]);

  const filteredTranscript = transcript.filter((entry) =>
    entry.message.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleClose = (e) => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    setOpen(false);
  };

  const handleCopy = () => {
    const transcriptText = transcript
      .map(
        (entry) =>
          `${
            entry.speaker === "Human"
              ? selectedCandidate?.firstName
              : entry.speaker
          }: ${entry.message}`
      )
      .join("\n");
    navigator.clipboard
      .writeText(transcriptText)
      .then(() => {
        showNotification("Conversation copied successfully!", "success");
      })
      .catch((err) => {
        showNotification("Failed to copy!", "error");
      });
  };

  const renderTranscription = () => {
    return (
      <div className="flex flex-col gap-[7px]">
        {isShowDetailOnly && (
          <div className="flex gap-1">
            <img src={BubbleCircle} alt="AIIcon" width={14} height={14} />
            <span className="text-[13px] leading-5 font-normal -tracking-[1%] text-[#1f1f1f]">
              AI Summary
            </span>
          </div>
        )}
        <div
          className={`flex flex-row justify-center items-start gap-4 mb-[52px] ${
            isShowDetailOnly && "mb-0"
          }`}
        >
          <div
            className={`w-full h-full min-h-[318px] max-w-[436px] flex flex-col justify-between items-end border border-[#E5E5E5] rounded-[8px_8px_32px_32px] ${
              isShowDetailOnly && "max-w-full"
            }`}
          >
            <div className="w-full h-full flex justify-center items-center flex-1">
              <img src={AudioVoiceIcon} alt="AudioVoiceIcon" />
            </div>
            <ReactAudioPlayer
              src={
                selectedCandidate && selectedCandidate.callSid
                  ? `https://ai-call-public-data.s3.eu-west-1.amazonaws.com/call-recordings/recording_${selectedCandidate?.callSid}.mp3`
                  : ``
              }
              controls={true}
              className="w-full bg-transparent"
            />
          </div>

          <div
            className={`w-full h-full max-w-[365px] flex flex-col gap-[52px] ${
              isShowDetailOnly ? "max-w-[302px]" : ""
            }`}
          >
            <div className="w-full h-full flex flex-col gap-[10px]">
              <div
                className={`w-full flex items-center gap-1 border-[1.2px] border-[#e5e5e5] rounded-lg py-2 px-[10px]`}
              >
                <img
                  src={SearchBlue}
                  alt="search-icon"
                  width={16}
                  height={16}
                />
                <input
                  type="text"
                  className="w-full text-[#121212] text-[13px] font-light leading-5 border-none focus:outline-none"
                  placeholder="Search for specific word"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <ul
                className={`px-[14px] py-4  ${
                  (transcript?.length === 0 ||
                    filteredTranscript?.length === 0) &&
                  isShowDetailOnly
                    ? "h-[calc(100%_-_36px_-_10px)]"
                    : "h-[270px]"
                } flex flex-col gap-8 border border-[#E5E5E5] rounded-lg overflow-auto scrollbar`}
              >
                {transcript?.length === 0 ||
                filteredTranscript?.length === 0 ? (
                  <div className="h-full flex justify-center items-center">
                    <div className="w-full max-w-[525px] mx-auto flex flex-col justify-center items-center gap-4 text-center">
                      <img
                        src={EmptyNoteSummary}
                        alt="EmptyNoteSummary"
                        className="max-w-full h-auto"
                      />
                      <p className="m-0 text-center text-sm leading-[20px] text-[#06102580] -tracking-[0.18px] font-medium">
                        {transcript?.length === 0
                          ? "The AI will fill this section for you after the call takes place"
                          : "No Data Found"}
                      </p>
                    </div>
                  </div>
                ) : (
                  filteredTranscript?.map((item, index) => (
                    <li
                      key={index}
                      className="bg-white flex gap-4 text-[#121212] text-sm font-light leading-[22px] -tracking-[0.14px]"
                    >
                      <span className="w-full max-w-[40px] whitespace-nowrap text-ellipsis overflow-hidden text-[#2764D2] text-xs font-normal leading-[18px]">
                        {item.speaker === "Human"
                          ? selectedCandidate?.firstName ||
                            selectedCandidate?.name
                          : item.speaker}
                        :
                      </span>
                      {item.message}
                    </li>
                  ))
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {isContactCandidate && (
        <ContactCandidate
          open={isContactCandidate}
          setOpen={setIsContactCandidate}
          selectedCandidate={selectedCandidate}
        />
      )}

      {isShowDetailOnly ? (
        renderTranscription()
      ) : (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth="sm"
          PaperProps={{
            style: {
              maxWidth: "977px",
              borderRadius: "16px",
            },
          }}
        >
          <DialogContent className="!py-[52px] !px-[92px] relative">
            <img
              src={CrossIcon}
              alt="crossIcon"
              className="ml-auto cursor-pointer absolute top-4 right-4"
              onClick={handleClose}
            />
            {loading ? (
              <div className="h-full min-h-[552px] flex justify-center items-center">
                {" "}
                <Loader />
              </div>
            ) : (
              <div className="font-hankengrotesk">
                <div className="text-[28px] leading-9 font-semibold text-left mb-8 text-[#353535]">
                  Transcript - {selectedCandidate?.firstName}
                </div>
                {renderTranscription()}
                <div className="w-full flex justify-between">
                  <button
                    className="btn border flex items-center justify-center border-[#2764D2] text-[#121212] text-[15px] font-semibold leading-5 py-[14px] px-4 w-[100px] h-12 text-center rounded-lg disabled:opacity-75 whitespace-nowrap"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <div className="flex gap-4">
                    <button
                      className="btn border flex items-center justify-center border-[#2764D2] text-[#121212] text-[15px] font-semibold leading-5 py-[14px] px-4 w-[164px] h-12 text-center rounded-lg disabled:opacity-75 whitespace-nowrap"
                      onClick={handleCopy}
                    >
                      Copy Conversation
                    </button>
                    <button
                      onClick={() => setIsContactCandidate(true)}
                      className="btn flex items-center justify-center bg-custom-primary text-white text-[15px] font-semibold leading-5 py-[14px] px-4 w-[164px] h-12   text-center rounded-lg whitespace-nowrap"
                    >
                      Contact Candidate
                    </button>
                  </div>
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default TranscriptionDetail;
