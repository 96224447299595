import { logDOM } from "@testing-library/react";
import { endPoints } from "services/endPoints";
import axiosInstance from "utils/axiosInstance";

export const getCompaniesCandidates = async (searchQuery) => {
  const endpoint = `${
    endPoints.company.getCompaniesCandidates
  }?search=${encodeURIComponent(searchQuery)}`;
  return await axiosInstance.get(endpoint);
};

export const getCompanyCandidateById = async (candidateId, data) => {
  const url = endPoints.company.getCompanyCandidateDetail.replace(
    ":candidate_id",
    candidateId
  );

  const res = await axiosInstance.get(url, data);
  return res?.data?.result?.[0];
};

export const createCompanyCandidate = async (data) => {
  debugger;
  const formData = new FormData();
  if (data.cvFile) {
    formData.append("file", data.cvFile);
    delete data.cvFile;
  }

  // Append the rest of the data as JSON
  formData.append("data", JSON.stringify(data));
  return await axiosInstance.post(
    endPoints.company.createCompanyCandidate,
    formData
  );
};

export const updateCompanyCandidate = async (candidate_id, data) => {
  const url = endPoints.company.updateCompanyCandidate.replace(
    ":candidate_id",
    candidate_id
  );

  return await axiosInstance.put(url, data);
};

export const updateCompany = async (candidateId, companyId, data) => {
  const url = endPoints.company.updateCompany
    .replace(":candidate_id", candidateId)
    .replace(":company_id", companyId);

  return await axiosInstance.post(url, data);
};

export const deleteCompany = async (candidateId, companyId) => {
  const url = endPoints.company.deleteCompany
    .replace(":candidate_id", candidateId)
    .replace(":company_id", companyId);

  return await axiosInstance.delete(url);
};

export const updateCandidateStatus = async (candidateId, data) => {
  const url = endPoints.company.updateCompanyCandidateStatus.replace(
    ":candidate_id",
    candidateId
  );

  return await axiosInstance.post(url, data);
};

export const getCandidateCompanies = async (
  candidateId,
  searchQuery,
  limit,
  offset,
  category = ""
) => {
  const endpoint = `${
    endPoints.company.getCompanies
  }?search=${encodeURIComponent(
    searchQuery
  )}&limit=${limit}&offset=${offset}&category=${
    category === "call_not_executed" ? null : category === "all" ? "" : category
  }`;
  return await axiosInstance.get(
    endpoint.replace(":candidate_id", candidateId)
  );
};

export const getCandidateCalls = async (
  candidate_id,
  searchQuery,
  limit,
  offset
) => {
  const endpoint = `${endPoints.company.getCalls}?search=${encodeURIComponent(
    searchQuery
  )}&limit=${limit}&offset=${offset}`;
  return await axiosInstance.get(
    endpoint.replace(":candidate_id", candidate_id)
  );
};

export const createCandidateCompanies = async (candidate_id, data) => {
  return await axiosInstance.post(
    endPoints.company.createCandidateCompanies.replace(
      ":candidate_id",
      candidate_id
    ),
    data
  );
};

export const executeAICall = async (candidateId, companyId) => {
  const url = endPoints.company.executeAICall
    .replace(":candidate_id", candidateId)
    .replace(":company_id", companyId);

  return await axiosInstance.post(url);
};

export const cancelSingleCall = async (candidateId, companyId) => {
  const url = endPoints.company.cancelSingleCall
    .replace(":candidate_id", candidateId)
    .replace(":company_id", companyId);

  return await axiosInstance.post(url);
};

export const cancelAllCalls = async (candidateId) => {
  const url = endPoints.company.cancelAllCalls.replace(
    ":candidate_id",
    candidateId
  );

  return await axiosInstance.post(url);
};

export const dialAllCalls = async (candidateId) => {
  const url = endPoints.company.dialAllCalls.replace(
    ":candidate_id",
    candidateId
  );

  return await axiosInstance.post(url);
};

export const updateCompanyNotes = async (candidate_id, company_id, data) => {
  const url = endPoints.company.updateCompanyNotes
    .replace(":candidate_id", candidate_id)
    .replace(":company_id", company_id);

  return await axiosInstance.post(url, data);
};
